@if (_brand) {
	@if (rect) {
		<img
			[bpTooltip]="noTooltip ? null : _brand.displayName"
			src="/assets/shared/components/payment-card-brand-rect-icons/{{
				_brand.scheme.alias?.kebabCase ?? _brand.kebabCase
			}}.png"
		/>
	} @else {
		<bp-svg-icon
			[bpTooltip]="noTooltip ? null : _brand.displayName"
			[name]="_brand.scheme.alias?.name ?? _brand.name"
		/>
	}
} @else {
	{{ src }}
}
