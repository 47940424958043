/* eslint-disable unicorn/prefer-switch */
/* eslint-disable complexity */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-classes-per-file */
import { mapValues, startCase, uniq, assign, camelCase, mapKeys, snakeCase, isNumber } from 'lodash-es';
import moment from 'moment';

import { CryptoCurrencyCode, FiatCurrency, FiatCurrencyCode } from '@bp/shared/models/currencies';
import { COUNTRIES_BY_REGION, Country, CountryCode } from '@bp/shared/models/countries';
import { normalizeHtml, FieldControlType } from '@bp/shared/models/metadata';
import { Dictionary, PickKeysByType, Stringify } from '@bp/shared/typings';
import { isEmpty, JwtPayload, JwtToken, uuid } from '@bp/shared/utilities/core';
import { PaymentCardBrand } from '@bp/shared/domains/payment-cards';

import { TelemetryService } from '@bp/frontend/services/telemetry';
import { SavedPaymentCardToken } from '@bp/frontend/components/payment-card/models';
import { CheckoutTheme, CheckoutType } from '@bp/frontend/domains/checkout/core';
import { ApmPaymentOptionSubType, PaymentOptionType, PaymentOptionTypeSnakeCaseLiterals, PspPaymentOptionType } from '@bp/frontend/models/business';
import { environment } from '@bp/frontend/environments';

import { tryDecodeBase64 } from '@bp/checkout-frontend/utilities';
import { TransactionInfo, ValidatableProperty } from '@bp/checkout-frontend/models';

import { EmbeddedData } from './embedded-data';
import { IBank } from './bank';

export enum PaymentOptionInstanceStatus {

	planned = 'planned',

	pending = 'pending',

	success = 'success',

	error = 'error',

}

export type CheckoutSessionValidatablePropertyNames = PickKeysByType<CheckoutSession, ValidatableProperty>;

const isNotProduction = environment.name !== 'production';

export class CheckoutSession extends EmbeddedData {
	id!: string;

	override country!: Country;

	accessToken?: {
		token: string;
		expires_in: number;
		decodedToken?: JwtPayload;
	};

	paymentMethods: IPaymentMethod[];

	paywithPaymentMethods!: IPaymentMethod[];

	paymentMethodInstances?: PaymentOptionInstance[];

	url?: {
		success?: string;
		failure?: string;
		cancel?: string;
		pending?: string;
	};

	limitsCurrenciesConversionRates: Dictionary<number>;

	baseCurrency!: FiatCurrency;

	currencyRates?: {
		base_currency: string;
		currency_rates: Dictionary<number>;
	};

	status!: 'closed' | 'open';

	allowCreateCreditCardToken!: boolean;

	get isClosed() {
		return this.status === 'closed';
	}

	checkoutType!: CheckoutType;

	initialCheckoutType!: CheckoutType;

	initialTheme!: CheckoutTheme;

	get isPayWithCheckout() {
		return this.checkoutType.isPaywith;
	}

	get isRegularLikeCheckout() {
		return this.checkoutType.isRegular || this.checkoutType.isVirtualTerminal || this.isPaymentLinkCheckout;
	}

	get isPaymentLinkCheckout() {
		return this.checkoutType.isPaymentLink;
	}

	get isPaymentCardTokenCheckout() {
		return this.checkoutType.isPaymentCardToken;
	}

	paywithMaxInstancesLimit?: number;

	orderSummary?: IOrderSummary;

	personalId?: string;

	personalIdType?: string;

	$amount: number;

	subscriptionPlanType?: 'free';

	isSuspended = false;

	get isFreeSubscriptionPlan() {
		return this.subscriptionPlanType === 'free';
	}

	constructor(public override dto: Partial<Stringify<CheckoutSession>>) {
		super(dto);

		this.dto = dto = mapKeys(dto, (value, key) => camelCase(key));

		if (this.accessToken?.token) {
			this.accessToken.decodedToken = JwtToken.decode(this.accessToken.token);

			/** On continue checkout opening the checkout key can be fetched only from the access token
			 * that we got on session fetching
			 */
			this.checkoutKey = this.accessToken.decodedToken.sub!;
		}

		if (!this.accessToken)
			TelemetryService.captureMessage('Session: accessToken is missing');

		this.checkoutType = CheckoutType.parse(this.checkoutType) ?? CheckoutType.regular;

		if (this.checkoutType.isPaywith)
			this.theme = CheckoutTheme.bright;

		this.$amount = Number(this.amount.value);

		this.limitsCurrenciesConversionRates = this.currencyRates?.currency_rates ?? {};

		if (this.currencyRates)
			this.baseCurrency = new FiatCurrency(<FiatCurrencyCode> this.currencyRates.base_currency);

		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
		this.paymentMethods ??= [];

		if (this.singlePaymentProvider && this.singlePaymentMethod === PaymentOptionType.creditCard)
			this.paymentProvider = this.singlePaymentProvider;

		this.theme = this.isHighlowQoneco || this.isHighlowXoro || this.isJamesAllen || this.isJamesAllenBluennile || this.isHighlowV2 || this.isSecurePharma
			? CheckoutTheme.transparent
			: this.theme;

		if (this.isLifacts)
			this.depositButtonText = 'Buy Now';

		this.paywithMaxInstancesLimit ??= 7;

		this.paymentMethods = this.paymentMethods
			.map(paymentMethod => {
				paymentMethod = { ...paymentMethod };

				paymentMethod.typeInSnakeCase = <PaymentOptionTypeSnakeCaseLiterals> (paymentMethod.type instanceof PaymentOptionType ? paymentMethod.type.snakeCase : snakeCase(paymentMethod.type));

				paymentMethod.type = PaymentOptionType.parseStrict(paymentMethod.type);

				paymentMethod.min_amount = Number(paymentMethod.min_amount) || 0;

				paymentMethod.max_amount = Number(paymentMethod.max_amount) || 1_000_000;

				paymentMethod.subtype = ApmPaymentOptionSubType.parse(paymentMethod.subtype)
					?? ApmPaymentOptionSubType.regular;

				paymentMethod.provider = (<string | undefined> paymentMethod.provider)?.toString() ?? 'none';

				paymentMethod.parsedJson = paymentMethod.json?.map(v => JSON.parse(tryDecodeBase64(v)));

				if (paymentMethod.type === PaymentOptionType.creditCard) {
					paymentMethod.credit_card_tokens &&= paymentMethod.credit_card_tokens
						.map(token => new SavedPaymentCardToken(
							<SavedPaymentCardToken><unknown>mapKeys(token, (value, key) => camelCase(key)),
						));
				}

				// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
				paymentMethod.additional_required_fields ||= [];

				paymentMethod.additional_required_fields = paymentMethod.additional_required_fields.map(camelCase);

				// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
				paymentMethod.additional_optional_fields ||= [];

				paymentMethod.additional_optional_fields = paymentMethod.additional_optional_fields.map(camelCase);

				// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
				paymentMethod.skip_required_fields ||= [];

				paymentMethod.skip_required_fields = paymentMethod.skip_required_fields.map(camelCase);

				paymentMethod.fields = paymentMethod.fields?.map(field => ({
					...field,
					controlType: FieldControlType.parseStrict(field.controlType),
				}));

				if (paymentMethod.is_currency_rates_service_unavailable)
					TelemetryService.captureError('currency rates service is unavailable');

				if (paymentMethod.type === PaymentOptionType.externalLink)
					paymentMethod.link = paymentMethod.parsedJson![0]['url'];

				if (paymentMethod.script)
					TelemetryService.captureMessage('script', paymentMethod.script);

				paymentMethod.ddc_htmls &&= isEmpty(paymentMethod.ddc_htmls)
					? null
					: <any>mapValues(
						paymentMethod.ddc_htmls,
						html => normalizeHtml(html),
					);

				if (paymentMethod.type === PaymentOptionType.crypto) {
					paymentMethod.crypto_currencies = isEmpty(paymentMethod.crypto_currencies)
						? <CryptoCurrencyCode[]>paymentMethod.brands
						: paymentMethod.crypto_currencies;
				}

				paymentMethod.currencyCode = paymentMethod.currency_rate
					? paymentMethod.currency_rate.base_currency
					: (isEmpty(paymentMethod.currencies) || paymentMethod.currencies.includes(this.currencyCode!)
						? this.currencyCode!
						: paymentMethod.currencies[0]);

				paymentMethod.amount ||= this.$amount;

				paymentMethod.displayAmount = paymentMethod.currency_rate
					? paymentMethod.amount / paymentMethod.currency_rate.rate
					: paymentMethod.amount;

				const currency = new FiatCurrency(paymentMethod.currencyCode);

				paymentMethod.displayAmount = currency.allowDecimal
					? Number(paymentMethod.displayAmount.toFixed(currency.decimalLimit))
					: Math.round(paymentMethod.displayAmount);

				if (!isEmpty(paymentMethod.currencies)) {
					paymentMethod.currencies = paymentMethod.currencies
						.sort(currencyCode => currencyCode === this.currencyCode ? -1 : 0);
				}

				return paymentMethod;
			});

		this.paymentMethods = this.paymentMethods
			.filter(paymentMethod => !paymentMethod.is_currency_rates_service_unavailable
				&& !this.hidePaymentProviders?.includes(paymentMethod.provider)
				&& !this.hidePaymentMethods?.includes(paymentMethod.type));

		this.paymentMethods
			.filter(paymetOption => !!paymetOption.provider)
			.forEach(paymentOption => {
				const assetsFolder = 'assets/images/payment-methods';
				let logos: ILogo[] | undefined;
				const optionType = paymentOption.type;
				const pspName = paymentOption.provider;

				if (optionType.isCreditCard || this.isMilliyfx && pspName === 'pay_maxis') {
					logos = [ 'mastercard', 'maestro', 'visa' ].map(brand => ({
						urls: [ `${ assetsFolder }/${ brand }` ],
						alt: 'Payment Card',
						displayName: this.theme.isBright ? 'Credit/debit card' : undefined,
					}));
				}

				let logoName = pspName;

				if (optionType === PaymentOptionType.wireTransfer)
					logoName = 'wire-transfer';

				if (optionType === PaymentOptionType.cryptoWallet || [ 'crypto_acquirer', 'nace_crypto' ].includes(pspName))
					logoName = 'cryptocurrency';

				const ecommpayLogoAsCreditCardStaticLogo = pspName === 'ecommpay'
					&& this.isCFDGlobe;

				const providerLogoAsCreditCardStaticLogo = [
					'hyper_pay',
					'introrix',
				].includes(pspName);

				if (ecommpayLogoAsCreditCardStaticLogo || providerLogoAsCreditCardStaticLogo)
					logoName = 'credit-cards-static';

				if ((this.isCFDGlobe) && pspName === 'plus_exchange')
					logoName = 'cryptocurrency';

				if (this.isMayfair && pspName === 'plus_exchange')
					logoName = 'plus-exchange_mayfair';

				if ((this.isRaxtrade) && pspName === 'wallit')
					logoName = 'wallit-raxtrade';

				if (this.isPriceAction && (pspName === 'confirmo'))
					logoName = 'confirmo_for_price_action';

				if (this.isDemoPreset) {
					if (pspName === 'master_pay')
						logoName = 'pay_pal';
					else if (pspName === 'flutterwave')
						logoName = 'stripe';
				}

				if (pspName === 'flutterwave' && (this.isEquitiFxPesa || this.isEquitiEGM)) {
					if (this.country.code === 'KE') {
						if (this.isEquitiEGM)
							logoName = 'visa-mastercard';

						if (this.isEquitiFxPesa)
							logoName = 'flutterwave-fxpesa';
					}

					if (this.country.code === 'CM')
						logoName = 'orange-and-mtn';

					if (this.country.code === 'ZM')
						logoName = 'mtn-and-zamtel';

					if (this.country.code === 'UG')
						logoName = 'mtn-and-airtel';
				}

				if (pspName === 'zotapay_apm') {
					const equitiZotaPayApmLogo = 'equiti_zotapay_apm';

					if (this.isEquitiEGM)
						logoName = equitiZotaPayApmLogo;

					if (this.isEquitiFxPesa)
						paymentOption.countryFlag = 'TZ';

					if (this.isEquitiJordanAndUAE) {
						if (this.country.code === 'CN')
							logoName = 'equiti_china_zotapay_apm';
						else if ((<CountryCode[]>[
							'ZA', 'VN', 'NG', 'IN', 'MY', 'TH', 'ID', 'CL', 'CR', 'EC', 'SV', 'MX', 'PA', 'PE', 'CO', 'AR', 'PH', 'BR', 'KR',
						]).includes(this.country.code))
							paymentOption.countryFlag = this.country.code;
						else if (![ 'CN', 'KE', 'GH', 'ZA', 'TZ' ].includes(this.country.code))
							logoName = equitiZotaPayApmLogo;
					}

					if (this.isEquitiSeychelles) {
						if (this.country.code === 'CN')
							logoName = 'equiti_china_zotapay_apm';
						else if (this.country.code === 'TH') {
							logos = [{
								urls: [ `${ assetsFolder }/equiti_th_zotapay_apm.png` ],
								displayName: 'Thai QR payment',
							}];
						} else if (this.country.code === 'BR')
							logoName = 'pix';
						else if ((<CountryCode[]>[
							'ZA', 'TZ', 'GH', 'KE', 'NG', 'UG', 'RW', 'ZM', 'BI', 'ID', 'MY', 'VN', 'TH', 'PH', 'CN', 'JP', 'MM', 'LA', 'AR', 'BR', 'CO', 'MX', 'SV', 'EC', 'CL', 'PA', 'PE', 'KR',
						]).includes(this.country.code))
							paymentOption.countryFlag = this.country.code;
						else
							logoName = equitiZotaPayApmLogo;
					}
				}

				if (this.isEquitiSeychelles || this.isEquitiJordanAndUAE) {
					if (optionType.isCrypto)
						logoName = 'equiti_cryptocurrency';

					if (pspName === 'gatetopay') {
						logoName = 'equiti_gatetopay';

						paymentOption.brands = uniq([ ...(paymentOption.brands ?? []), PaymentCardBrand.masterCard.name ]);
					}

					if (pspName === 'vaultspay')
						logoName = 'visa-mastercard';

					if (pspName === 'bvnk')
						logoName = 'equiti_bvnk';

					if (pspName === 'hayvn_pay')
						logoName = 'hayvn_cryptos';

					if (pspName === 'kora_pay_bank_transfer')
						logoName = 'equiti_kora_pay_bank_transfer';

				}

				if (pspName === 'selcom' && (this.isEquitiEGM || this.isEquitiFxPesa || this.isEquitiSeychelles))
					logoName = 'equiti_selcom';

				if (optionType.isCreditCard) {
					if (this.isEquitiJordanAndUAE || this.isEquitiSeychelles) {
						logos = [{
							urls: [ `${ assetsFolder }/visa-mastercard.png` ],
						}];
					}

					if (this.isFundYourFx) {
						logos = [{
							urls: [ `${ assetsFolder }/visa-mastercard_for_fund_your_fx.png` ],
						}];
					}

					if (this.isEquitiUAE || pspName === 'credit_card_that_opens_new_tab') {
						logos = [{
							urls: [ `${ assetsFolder }/equiti_credit_card_brands.png` ],
						}];
					}

					if (this.isFundedPeaks && this.theme.isBright) {
						logos = [{
							urls: [ `${ assetsFolder }/funded_peaks_credit_card_logo.png` ],
						}];
					}

				}

				if (paymentOption.installments_order_summary || pspName === 'credit_guard') {
					logos = [ 'mastercard', 'maestro', 'visa' ].map(brand => ({
						urls: [ `${ assetsFolder }/${ brand }` ],
						alt: 'Installments',
						displayName: 'Installments',
					}));
				}

				if (pspName === 'embedded_bp_three_ds') {
					logos = [ 'mastercard', 'maestro', 'visa' ].map(brand => ({
						urls: [ `${ assetsFolder }/${ brand }` ],
						displayName: 'Embedded 3DS Local',
					}));
				}

				if (pspName === 'embedded_bp_three_ds_mock') {
					logos = [ 'mastercard', 'maestro', 'visa' ].map(brand => ({
						urls: [ `${ assetsFolder }/${ brand }` ],
						displayName: 'Embedded 3DS Mock',
					}));
				}

				if (this.isLegacyFx && pspName === 'ecommpay_apm')
					logoName = 'ecommpay_apm_legacyfx';

				if (this.isEzInvest) {
					if (pspName === 'innatech') {
						const trustlyCountries: CountryCode[] = [
							'BE', 'CZ', 'DK', 'EE', 'FI', 'LV', 'LT', 'NO', 'SK', 'SE', 'GB', 'ES',
						];

						if (trustlyCountries.includes(this.country.code))
							logoName = 'trustly';

						const idealCountries: CountryCode[] = [
							'NL',
						];

						if (idealCountries.includes(this.country.code))
							logoName = 'ideal';

						const sofortCountries: CountryCode[] = [
							'AT', 'DE', 'IT', 'PL', 'CH', 'FR',
						];

						if (sofortCountries.includes(this.country.code))
							logoName = 'sofort-gray';
					}

					if (pspName === 'payretailers')
						logoName = 'payretailers_ezinvest';

					if (pspName === 'foxapay')
						logoName = 'foxapay_ezinvest';

				}

				if (this.isFocusOption) {
					if (pspName === 'coinflow')
						logoName = 'coinflow_focusoption';

					if (pspName === 'payretailers')
						logoName = 'payment_methods_latam';
				}

				if (this.isBitlevex && pspName === 'rupee')
					logoName = 'rupee_bitlevex';

				if (this.isGFMarkets) {
					if ([ 'neteller', 'skrill', 'fasa_pay', 'paysafe', 'perfect_money' ].includes(pspName))
						logoName = `gfm_${ pspName }`;

					if (optionType.isCreditCard) {
						logos = [ 'amex', 'visa', 'master', 'jcb', 'discover' ].map(brand => ({
							urls: [ `${ assetsFolder }/gfm_${ brand }` ],
						}));
					}

					if (optionType === PaymentOptionType.cryptoWallet) {
						logos = [ 'bitcoin', 'tether' ].map(v => ({
							urls: [ `${ assetsFolder }/gfm_${ v }` ],
						}));
					}

					if (optionType === PaymentOptionType.wireTransfer)
						logoName = 'gfm_wire';
				}

				if (this.isGFMarkets && pspName === 'alpha_po')
					logoName = 'cryptos-btc-ltc-eth';

				if ((this.isGFMarkets || this.isExcentCapital) && pspName === 'eu_paymentz')
					logoName = 'gfm_eu_paymentz';

				if (this.isColmex && pspName === 'worldpay')
					logoName = 'ideal';

				if (this.isIQuoto && pspName === 'safe_charge')
					logoName = 'ideal';

				if (this.isSodaCell && pspName === 'volt')
					logoName = 'pix';

				if (this.isExcentCapital && pspName === 'hayvn_pay')
					logoName = 'gfm_bitcoin';

				if (this.isEcg && pspName === 'hayvn_pay')
					logoName = 'hayvn_pay_ecg';

				if (this.isTradeQuo) {
					if (pspName === 'echelon_pay')
						logoName = 'echelon_pay_trade_quo';

					if (pspName === 'payment_asia')
						logoName = 'payment_asia_trade_quo';

					if (pspName === 'alps')
						logoName = 'alps_trade_quo';
				}

				if (this.isTradeQuoAfrica && pspName === 'payretailers') {
					const africaCountries = COUNTRIES_BY_REGION.get('Africa');

					if (africaCountries?.some(({ code }) => code === this.country.code))
						logoName = 'payretailers_for_trade_quo_africa';
				}

				if (this.isHarvestRiskSolutions && pspName === 'directa24')
					logoName = 'directa24_harvest_risk_solutions';

				if ((this.isCallistoCanadaCIS || this.isCallistoRelocation2canada || this.isCallistoVivezAuCanada) && pspName === 'stripe')
					logoName = 'stripe_local_payment_methods';

				if (this.isMalfex && pspName === 'hayvn_pay')
					logoName = 'hayvn_cryptos';

				if (this.isPUprimeVTmarkets && pspName === 'solid_pay')
					logoName = 'solid_pay_mastercard_visa';

				if (this.isSportsEvents365 && pspName === 'payretailers')
					logoName = 'payretailers_sports_events_365';

				if (this.isFundedPeaks && pspName === 'confirmo')
					logoName = 'funded_peaks_confirmo';

				if (optionType === PaymentOptionType.externalLink && pspName === 'perfect_money')
					logoName = 'perfect_money_external_link';

				if (this.isKroxio) {
					if (pspName === 'directa24')
						logoName = 'directa24_for_kroxio';

					if (pspName === 'interac_via_isettle')
						logoName = 'interac_via_isettle_for_kroxio';

					if (pspName === 'stic_pay_via_isettle')
						logoName = 'stic_pay_via_isettle_for_kroxio';
				}

				if (this.isFxTampa || this.isKroxio) {
					if (optionType.isCreditCard) {
						logos = [ 'mastercard', 'visa' ].map(brand => ({
							urls: [ `${ assetsFolder }/${ brand }` ],
							alt: 'Payment Card',
							displayName: this.theme.isBright ? 'Credit/debit card' : undefined,
						}));
					}

					if (pspName === 'web_pay_z')
						logoName = 'web_pay_z_for_kroxio_fxtampa';

					if (pspName === 'pay_maxis_crypto')
						logoName = 'pay_maxis_crypto_for_kroxio_fxtampa';

					if (pspName === 'eu_paymentz')
						logoName = 'eu_paymentz_for_kroxio_fxtampa';
				}

				if (this.isSolisMarkets) {
					if (pspName === 'aopay')
						logoName = 'aopay_solismarkets';

					if (pspName === 'echelon_pay')
						logoName = 'echelon_pay_just_logo';
				}

				if (this.isDominionMarkets && pspName === 'eu_paymentz')
					logoName = 'eu_paymentz_for_dominion_markets';

				if (this.isMarketsCom && pspName === 'let_know') {
					if (optionType.isApm)
						logoName = 'let_know_binancepay';

					if (optionType.isCrypto)
						logoName = 'crypto_payment';
				}

				if (this.isGo4rex && pspName === 'directa24')
					logoName = 'local_payment_method';

				if (this.isOriginFx) {
					if (pspName === 'aopay')
						logoName = `originfx_${ pspName }`;

					if (pspName === 'crypto_chill')
						logoName = `originfx_${ pspName }`;

					if (pspName === 'pay_pal')
						logoName = 'pay_pal_for_originfx';

					if (pspName === 'payretailers') {
						const countrySuffix = (<Record<string, string>>{
							AR: 'argentina',
							BR: 'brazil',
							BF: 'burkina_faso',
							CM: 'cameroon',
							CL: 'chile',
							CO: 'colombia',
							CR: 'costa_rica',
							EC: 'ecuador',
							GT: 'guatemala',
							CI: 'ivory_coast',
							MX: 'mexico',
							NG: 'nigeria',
							PA: 'panama',
							PE: 'peru',
							SN: 'senegal',
							TZ: 'tanzania',
							UG: 'uganda',
							ZM: 'zambia',
						})[this.country.code] ?? null;

						if (countrySuffix)
							logoName = `${ pspName }_for_originfx_${ countrySuffix }`;
					}

					if (pspName === 'zotapay_apm') {
						if (this.country.code === 'BD')
							logoName = `${ pspName }_for_originfx_bangladesh`;

						if (this.country.code === 'GH')
							logoName = `${ pspName }_for_originfx_ghana`;

						if (this.country.code === 'IN')
							logoName = `${ pspName }_for_originfx_india`;

						if (this.country.code === 'KE')
							logoName = `${ pspName }_for_originfx_kenya`;

						if (this.country.code === 'NG')
							logoName = `${ pspName }_for_originfx_nigeria`;

						if (this.country.code === 'PK')
							logoName = `${ pspName }_for_originfx_pakistan`;

						if (this.country.code === 'ZA')
							logoName = `${ pspName }_for_originfx_south_africa`;
					}
				}

				if (this.isSfxGlobal) {
					if (pspName === 'confirmo')
						logoName = `sfx_global_${ pspName }`;

					if (pspName === 'pay_maxis')
						logoName = `sfx_global_${ pspName }`;
				}

				if (this.isRazeMarkets) {
					if (pspName === 'eu_paymentz')
						logoName = 'eu_paymentz_for_raze_markets';

					if (pspName === 'hayvn_pay')
						logoName = 'hayvn_pay_for_raze_markets';
				}

				if (this.isFunderPro) {
					if (pspName === 'open_banking_via_pay_maxis')
						logoName = 'open_banking_via_pay_maxis_for_funderpro';

					if (pspName === 'payfuture')
						logoName = 'payfuture_for_funderpro';
				}

				if (this.isLearnTradeGo) {
					if (pspName === 'stripe')
						logoName = 'ideal';

					if (pspName === 'eu_paymentz')
						logoName = 'interac';
				}

				if (this.isFundedNext && pspName === 'eu_paymentz')
					logoName = 'visa-mastercard';

				if (this.isMasterFunders) {
					if (pspName === 'directa24' && [ 'NG', 'MY', 'PH' ].includes(this.country.code))
						logoName = `${ pspName }_${ this.country.code.toLowerCase() }_for_master_funders`;

					if (pspName === 'skrill' && [ 'NG', 'MY', 'PH', 'GB' ].includes(this.country.code))
						logoName = `${ pspName }_${ this.country.code.toLowerCase() }_for_master_funders`;
				}

				if (this.theme.isBright) {
					// eslint-disable-next-line unicorn/no-lonely-if
					if (pspName === 'volt')
						logoName += '_h';
				}

				if (optionType === PaymentOptionType.externalLink && paymentOption.parsedJson![0]['logoUrl']) {
					logos = [{
						urls: [ paymentOption.parsedJson![0]['logoUrl'] ],
					}];
				}

				paymentOption.logos = logos ?? [{
					urls: [ `${ assetsFolder }/${ logoName }` ],
					displayName: paymentOption.installments_order_summary ? 'Installments' : undefined,
					alt: startCase(pspName),
				}];

				if ([ 'b2_bin_pay_apm', 'plus_exchange', 'bitpay', 'hayvn_pay' ].includes(pspName))
					paymentOption.subtype = ApmPaymentOptionSubType.crypto;
			});

		this.paymentMethods
			.filter(v => v.has_banks && !this.showPspPaymentOptionsAsDropdown)
			.forEach(option => (option.type = PaymentOptionType.banks));

		const paymentMethodsOrder = [
			PaymentOptionType.paywith,
			PaymentOptionType.creditCard,
			PaymentOptionType.apm,
			PaymentOptionType.banks,
			PaymentOptionType.crypto,
			PaymentOptionType.cryptoWallet,
			PaymentOptionType.externalLink,
			PaymentOptionType.wireTransfer,
		];

		if (this.isPayWithCheckout) {
			this.amountLock = true;

			this.currencyLock = true;

			this.payMode = true;

			this.paywithPaymentMethods = this.paymentMethods;
		}

		if (this.isRegularLikeCheckout && isNotProduction) {
			this.paywithPaymentMethods = this.paymentMethods.filter(method => method.used_in_paywith);

			if (this.paywithPaymentMethods.length > 0 && this.amountLock && this.currencyLock) {
				this.paymentMethods.push(<any>{
					type: PaymentOptionType.paywith,
					logos: [{
						urls: [ 'assets/paywith-logo' ],
						alt: 'PayWith',
					}],
				});
			}

		}

		this.paymentMethods = this.paymentMethods
			.sort((a, b) => paymentMethodsOrder.indexOf(a.type) - paymentMethodsOrder.indexOf(b.type))
			.sort(a => this.isMayfair && a.provider === 'plus_exchange' ? -1 : 0);

		this.paymentMethodInstances = this.paymentMethodInstances?.map((instance, index) => {
			const instanceDto = <PaymentOptionInstanceSummary><unknown>mapKeys(instance, (v, k) => camelCase(k));

			const instancePaymentMethodType = PaymentOptionType.parseStrict(instanceDto.paymentMethodType);

			return new PaymentOptionInstance({
				...instanceDto,
				index,
				paymentOption: this.paymentMethods.find(
					method => (instancePaymentMethodType.isApm ? [ PaymentOptionType.apm, PaymentOptionType.banks ].includes(method.type) : method.type === instancePaymentMethodType) && method.provider === instanceDto.paymentMethodPspName,
				),
			});
		});

		if (this.isJamesAllen || this.isJamesAllenBluennile) {
			this.tickSaveCreditCardCheckboxByDefault = this.hideSaveCreditCardCheckbox = this.showPlaceholderForCardNumber = this.showPlaceholderForCvv = this.dontRenderCardHolderNameInUppercase = true;

			this.hideCardHolderNameWhenFullNameIsAvailable = false;
		}

		if (this.isDavidShields)
			this.hideCardHolderNameWhenFullNameIsAvailable = false;

		if (this.orderSummary) {
			this.orderSummary.$$details = <IOrderSummary><unknown>mapValues(
				this.orderSummary,
				(value: number | string, _key: string) => {
					if (isNumber(value)) {
						const parsedDate = moment(value, [
							moment.HTML5_FMT.DATETIME_LOCAL,
							moment.HTML5_FMT.DATE,
							moment.ISO_8601,
							'X',
						]);

						if (parsedDate.isValid())
							return parsedDate.format('LLL');
					}

					return value.toString().trim();
				},
			);

			this.orderSummary.$$details = mapKeys(this.orderSummary.$$details, (v, k) => k.trim());

			delete this.orderSummary.$$details['$$details'];

			delete this.orderSummary.$$details['items'];
		}

		if (this.url) {
			this.url.cancel = this.url.cancel?.trim();

			this.url.success = this.url.success?.trim();

			this.url.failure = this.url.failure?.trim();

			this.url.pending = this.url.pending?.trim();
		}
	}

	toJSON() {
		return this.dto;
	}
}

export interface IRequestSession {

	cashier_key: string;

	cashier_token?: string;

	order_id?: string;

	platform_id?: string;

	first_name?: string;

	last_name?: string;

	card_holder_name?: string;

	email?: string;

	currency?: FiatCurrencyCode;

	country?: CountryCode;

	currency_lock?: boolean;

	amount?: number;

	amount_lock?: boolean;

	theme?: string | null;

	deposit_button_text?: string;

	birth_date?: number;

	pay_mode?: boolean;

	hide_languages_dropdown?: boolean;

	hide_ui?: boolean;

	// deprecated
	show_redirect_message?: boolean;

	show_redirect_message_on_final_status_page?: boolean;

	show_processing_message_on_final_status_page?: boolean;

	tick_save_credit_card_checkbox_by_default?: boolean;

	hide_save_credit_card_checkbox?: boolean;

	show_placeholder_for_card_number?: boolean;

	show_placeholder_for_cvv?: boolean;

	dont_render_card_holder_name_in_uppercase?: boolean;

	hide_card_holder_name_when_full_name_is_available?: boolean;

}

export interface IPaymentMethod {

	type: PaymentOptionType;

	typeInSnakeCase: PaymentOptionTypeSnakeCaseLiterals;

	subtype: ApmPaymentOptionSubType;

	provider: string | 'none';

	logos: ILogo[];

	brands?: string[];

	banks?: IBank[];

	banks_by_payment_type?: Map<string, IBank[]>;

	has_banks?: boolean;

	banks_payment_types?: string[];

	amount: number;

	min_amount: number;

	max_amount: number;

	hide_amount?: boolean;

	is3d_secure?: boolean;

	currencies?: FiatCurrencyCode[];

	crypto_currencies: CryptoCurrencyCode[];

	note?: string;

	psp_badge?: string;

	has_badge?: boolean;

	currency_rate?: {
		base_currency: FiatCurrencyCode;
		target_currency: FiatCurrencyCode;
		rate: number;
	};

	currencyCode: FiatCurrencyCode;

	additional_required_fields: string[];

	additional_optional_fields: string[];

	skip_required_fields: string[];

	optional_fields: string[];

	open_in_new_window: boolean;

	open_safari_in_new_window: boolean;

	script: string;

	process_as_credit_card: boolean;

	credit_card_tokens?: SavedPaymentCardToken[];

	is_currency_rates_service_unavailable: boolean;

	browser_checkout_url?: string;

	// wire transfer payment method related fields
	json?: string[];

	parsedJson?: Dictionary<any>[];

	// link payment method related fields
	link: string;

	countryFlag?: CountryCode;

	installments_order_summary?: IInstallmentsOrderSummary;

	/**
	 * PSPs fingerprints htmls
	 * the key is the psps name
	 */
	ddc_htmls?: Dictionary<string>;

	auto_deposit: boolean;

	used_in_paywith: boolean;

	personal_id_types?: IPersonalIdType[];

	fields?: {
		name: string;
		title: string;
		controlType: FieldControlType;
		required: boolean;
		items?: { id: string; title: string }[];
	}[];

	displayAmount: number;

}

export class PaymentOptionInstanceSummary {

	readonly id: string;

	amount?: number;

	status!: PaymentOptionInstanceStatus;

	paymentMethodType!: PaymentOptionType | PspPaymentOptionType;

	paymentMethodPspName!: string;

	transactionId?: string;

	private _processingDate?: string | undefined;

	get processingDate(): string | undefined {
		return this._processingDate;
	}

	set processingDate(value: string | undefined) {
		this._processingDate = value;

		const momentProcessingDate = moment(value);

		this.processingDateDisplay = momentProcessingDate.isValid()
			? momentProcessingDate.format('lll')
			: value;
	}

	processingDateDisplay?: string;

	constructor(dto: Partial<PaymentOptionInstanceSummary>) {
		assign(this, dto);

		this.id ||= uuid();
	}
}

export class PaymentOptionInstance extends PaymentOptionInstanceSummary {

	index!: number;

	paymentOption!: IPaymentMethod;

	transaction?: TransactionInfo;

	get isApm() {
		return this.paymentOption.type.isApm;
	}

	get isCreditCard() {
		return this.paymentOption.type.isCreditCard;
	}

	get isPlanned() {
		return this.status === PaymentOptionInstanceStatus.planned;
	}

	get isPending() {
		return this.status === PaymentOptionInstanceStatus.pending;
	}

	get isSuccess() {
		return this.status === PaymentOptionInstanceStatus.success;
	}

	get isError() {
		return this.status === PaymentOptionInstanceStatus.error;
	}

	hasSkippedForm = false;

	constructor(dto: Partial<PaymentOptionInstance>) {
		super(dto);

		assign(this, dto);

		this.paymentMethodType = this.paymentOption.type;

		this.paymentMethodPspName = this.paymentOption.provider;
	}

	markAsSkippedForm() {
		this.hasSkippedForm = true;
	}
}

export interface IInstallmentsOrderSummary {

	description?: Record<string, string>;

	installment_options?: IInstallmentOption[];

	estimated_amount: number;

}

export interface IInstallmentOption {
	id: string;
	name: string;
}

export interface IOrderSummary {

	items: IOrderSummaryItem[];

	$$details: Record<string, any>;

	[other: string]: any;

}

export interface IOrderSummaryItem {

	id: string;

	name: string;

	quantity: number;

	price: number;

	url?: string;

	image_url?: string;

}

export interface IPersonalIdType {

	name: string;

	title: string;

}

export interface ILogo {

	urls?: string[]; // array of urls for fallback

	displayName?: string;

	alt?: string;

}
