import { Enumeration } from '@bp/shared/models/core/enum';

export class CheckoutWindowContext extends Enumeration {

	static iframe = new CheckoutWindowContext();

	static tab = new CheckoutWindowContext();

	static popup = new CheckoutWindowContext();

}
