import { inject, OnDestroy, Type, Injectable } from '@angular/core';

import { HttpConfigService } from '@bp/frontend/services/http';
import { LocalBackendState, MockedBackendState } from '@bp/frontend/services/persistent-state-keepers';
import { PLATFORM } from '@bp/frontend/services/environment';
import { ZoneService } from '@bp/frontend/rxjs';

export interface IApiMockPlugin {

	init: (urlPrefix: string) => void;

	shutdown: () => void;

}

type ApiMockPluginModuleImport = () => Promise<{ default: Type<IApiMockPlugin> }>;

@Injectable({ providedIn: 'root' })
export class ApiMockPluginsManagerService implements OnDestroy {

	private readonly __httpConfigService = inject(HttpConfigService);

	private readonly __platform = inject(PLATFORM);

	private readonly __zoneService = inject(ZoneService);

	private readonly __registeredApiMockPluginModules = new Set();

	private __lastLoadedApiMockPlugin?: IApiMockPlugin;

	private __initingApiMockPluginsCounter = 0;

	async register(
		moduleType: Type<any>,
		apiMockPluginImportPromise: ApiMockPluginModuleImport | ApiMockPluginModuleImport[],
	): Promise<void> {
		if (!MockedBackendState.isActive || this.__registeredApiMockPluginModules.has(moduleType))
			return;

		// We need to explicitly run the code in Angular Zone, because registration happens in module
		// constructors, which could be preloaded with ngxQuicklink, which runs them outside of zone, causing
		// mocked HTTP requests return answer out of zone, which leads to bugs.
		await this.__zoneService.runInAngularZone(async () => {
			this.__registeredApiMockPluginModules.add(moduleType);

			this.__initingApiMockPluginsCounter++;

			MockedBackendState.apiMockPluginsInitStarted();

			const apiMockPluginImports = Array.isArray(apiMockPluginImportPromise)
				? apiMockPluginImportPromise
				: [ apiMockPluginImportPromise ];

			for (const apiMockPluginImport of apiMockPluginImports) {
				// eslint-disable-next-line no-await-in-loop
				const apiMockPluginModule = await apiMockPluginImport();

				this.__lastLoadedApiMockPlugin = new apiMockPluginModule.default(this.__platform);
			}

			this.__lastLoadedApiMockPlugin!.init(
				LocalBackendState.isActive
					? this.__httpConfigService.backendBaseSegment.replace('api', 'api-mock')
					: this.__httpConfigService.backendBaseSegment,
			);

			this.__initingApiMockPluginsCounter--;

			if (this.__initingApiMockPluginsCounter === 0)
				MockedBackendState.apiMockPluginsInitFinished();
		});
	}

	ngOnDestroy(): void {
		this.__lastLoadedApiMockPlugin?.shutdown();
	}

}
