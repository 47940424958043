import { Enumeration } from '@bp/shared/models/core/enum';

export class PaymentCardTokenStatus extends Enumeration {

	static active = new PaymentCardTokenStatus();

	static expired = new PaymentCardTokenStatus();

	get isActive(): boolean {
		return this === PaymentCardTokenStatus.active;
	}

	get isExpired(): boolean {
		return this === PaymentCardTokenStatus.expired;
	}

}
