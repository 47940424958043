import { animate, animation, state, style, transition, trigger, useAnimation } from '@angular/animations';

import { NORMAL } from './variables';

const CLOSE = animation(
	[
		style({ height: '{{toHeight}}px' }),
		animate(
			'{{timing}}s {{ease}}',
			style({ height: '{{fromHeight}}px' }),
		),
	],
);

const OPEN = animation(
	[
		style({
			height: '{{fromHeight}}px',
			display: 'block',
		}),
		animate(
			'{{timing}}s {{ease}}',
			style({ height: '{{toHeight}}px' }),
		),
	],
);

export const LINE_CLAMP_EXPAND = trigger('lineClampExpand', [
	state('true', style({ '-webkit-line-clamp': 'initial' })),
	transition('1 => 0', useAnimation(CLOSE, NORMAL)),
	transition('0 => 1', useAnimation(OPEN, NORMAL)),
]);
