import { coerceBooleanProperty } from '@angular/cdk/coercion';
import type { AfterViewChecked } from '@angular/core';
import { Directive, ElementRef, Input, TemplateRef, ViewContainerRef } from '@angular/core';

import { EnvironmentService } from '@bp/frontend/services/environment';

@Directive({
	selector: '[bpFeatureUnderDevelopment]',
})
export class FeatureUnderDevelopmentStructuralDirective implements AfterViewChecked {

	@Input() bpFeatureUnderDevelopment?: '' | null;

	@Input({ transform: coerceBooleanProperty })
	bpFeatureUnderDevelopmentShowBorder = true;

	private readonly _$host = <Comment> this._hostRef.nativeElement;

	private _isDecorated = false;

	private _$targetsForDecoration?: HTMLElement[];

	constructor(
		private readonly _hostRef: ElementRef,
		private readonly _viewContainerRef: ViewContainerRef,
		private readonly _tplRef: TemplateRef<any>,
		private readonly _environment: EnvironmentService,
	) { }

	ngAfterViewChecked(): void {
		this._$targetsForDecoration ??= this._whenStagingOrDevRenderViewAndReturnTargetElements();

		if (this._environment.isNotProduction
			&& this._$host.isConnected
			&& !this._isDecorated
			&& this.bpFeatureUnderDevelopmentShowBorder
		)
			this._decorateToDrawAttention();
	}

	private _decorateToDrawAttention() {
		for (const $target of this._$targetsForDecoration ?? []) {
			this._findClosestElementWithNotInlineDisplay($target)
				.classList
				.add('feature-under-development');
		}

		this._isDecorated = true;
	}

	private _findClosestElementWithNotInlineDisplay($target: HTMLElement): HTMLElement {
		while (this._getDisplayStyle($target) === 'inline') {
			if ($target.firstElementChild instanceof HTMLElement)
				$target = $target.firstElementChild;
		}

		return $target;
	}

	private _getDisplayStyle($element: Element) {
		return getComputedStyle($element).display;
	}

	private _renderView() {
		const view = this._viewContainerRef
			.createEmbeddedView(this._tplRef);

		view.detectChanges();

		return view;
	}

	private _whenStagingOrDevRenderViewAndReturnTargetElements(): HTMLElement[] {
		return this._environment.isNotProduction
			? this._renderView()
				.rootNodes
				.filter(v => v instanceof HTMLElement)
			: [];
	}

}
