import { NgModule } from '@angular/core';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { A11yModule } from '@angular/cdk/a11y';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatLegacyRippleModule } from '@angular/material/legacy-core';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule } from '@angular/material/legacy-select';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyTabsModule } from '@angular/material/legacy-tabs';
import { MatStepperModule } from '@angular/material/stepper';
import { MatLegacyCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';

const exposed = [
	MatLegacyFormFieldModule,
	MatLegacyAutocompleteModule,
	MatLegacyInputModule,
	MatLegacySelectModule,
	MatIconModule,
	MatLegacyProgressBarModule,
	MatDatepickerModule,
	MatMomentDateModule,
	MatLegacyButtonModule,
	MatLegacyTabsModule,
	MatStepperModule,
	MatLegacyCheckboxModule,
	MatLegacyProgressSpinnerModule,
	MatLegacyRippleModule,
	A11yModule,
];

@NgModule({
	imports: exposed,
	exports: exposed,
	providers: [
		{
			provide: STEPPER_GLOBAL_OPTIONS,
			useValue: { displayDefaultIndicatorType: false },
		},
	],
})
export class MaterialsModule { }
