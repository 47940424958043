export function amountInSuffixNotation(amount: number): string {
	const suffixes = [ '', 'K', 'M', 'B', 'T' ]; // Add more suffixes as needed
	let number = amount;

	if (number >= 1000) {
		let suffixIndex = 0;

		while (number >= 1000 && suffixIndex < suffixes.length - 1) {
			number /= 1000;

			suffixIndex++;
		}

		return Math.round(number) + suffixes[suffixIndex];
	}

	return number.toString();
}
