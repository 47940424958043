/* eslint-disable @typescript-eslint/naming-convention */
import { BehaviorSubject, combineLatest, firstValueFrom, merge, Observable, Subscription } from 'rxjs';
import { first, map, startWith } from 'rxjs/operators';
import { isEmpty, isNil, lowerCase, omit, omitBy, uniq } from 'lodash-es';
import { decode, encodeURL } from 'js-base64';

import { inject, Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NavigationExtras, Router, UrlTree } from '@angular/router';
import { LegacyFloatLabelType, MatLegacyFormFieldAppearance } from '@angular/material/legacy-form-field';

import { ResponseStatusCode } from '@bp/shared/models/core';
import { isURL } from '@bp/shared/utilities/core';
import { JsonNamingStrategy, JSON_NAMING_STRATEGY_HEADER } from '@bp/shared/models/common';
import { FiatCurrency } from '@bp/shared/models/currencies';

import { TelemetryService } from '@bp/frontend/services/telemetry';
import { HttpConfigService } from '@bp/frontend/services/http';
import { CypherService } from '@bp/frontend/services/core';
import { BpError } from '@bp/frontend/models/core';
import { HostNotifierService, LanguagesService } from '@bp/frontend/domains/checkout/services';
import { AsyncVoidSubject, OptionalBehaviorSubject } from '@bp/frontend/rxjs';
import { SavedPaymentCardToken } from '@bp/frontend/components/payment-card/models';
import { LocalBackendState, MockedBackendState } from '@bp/frontend/services/persistent-state-keepers';
import { CheckoutLaunchButtonMode, CheckoutTheme, CheckoutType, CheckoutWindowContext } from '@bp/frontend/domains/checkout/core';
import { PaymentOptionType } from '@bp/frontend/models/business';
import { EnvironmentService } from '@bp/frontend/services/environment';
import { MomentTimezoneService } from '@bp/frontend/features/moment/services';
import { GeolocationService } from '@bp/frontend/features/geolocation';

import {
	EmbeddedData, IBrowserDetails, IPaymentMethod, IS_MPI, RequestDeposit, CheckoutSession, Transaction, TransactionInfo, IBank, isMockSession
} from '@bp/checkout-frontend/models';
import { documentWrite, isEmbedded, logNonCriticalPaymentRequestProperties, trimLogHeaderUrl } from '@bp/checkout-frontend/utilities';

import { PaymentApiService } from './api/payments-api.service';
import { SessionApiService } from './api/session-api.service';
import { PaymentOptionInstancesManager } from './payment-option-instances-manager.service';

@Injectable({
	providedIn: 'root',
})
export class AppService {

	static initialUrl = AppService.__getInitialUrl();

	private static __getInitialUrl(): string {
		const url = new URL(window.location.href);

		try {
			if (window.location.href === `${ window.location.origin }/`)
				return sessionStorage.getItem('initialUrl') ?? window.location.href;

			if (MockedBackendState.isActive)
				url.searchParams.set('useMockBackend', 'true');

			sessionStorage.setItem('initialUrl', url.toString());
		} catch {
			// sessionStorage can be disabled
		}

		return url.toString();

	}

	private readonly __httpConfigService = inject(HttpConfigService);

	private readonly __router = inject(Router);

	private readonly __languagesService = inject(LanguagesService);

	private readonly __paymentsApi = inject(PaymentApiService);

	private readonly __sessionApi = inject(SessionApiService);

	private readonly __cipherService = inject(CypherService);

	private readonly __telemetry = inject(TelemetryService);

	private readonly __environment = inject(EnvironmentService);

	private readonly __hostNotifier = inject(HostNotifierService);

	private readonly __paymentOptionInstancesManager = inject(PaymentOptionInstancesManager);

	private readonly __session$ = new OptionalBehaviorSubject<CheckoutSession>();

	private readonly __momentTimezoneService = inject(MomentTimezoneService);

	private readonly __geolocationService = inject(GeolocationService);

	session$ = this.__session$.asObservable();

	get session(): CheckoutSession | null {
		return this.__session$.value ?? null;
	}

	get isBrightTheme(): boolean {
		return !!this.session?.theme.isBright;
	}

	get isNotBrightTheme(): boolean {
		return !this.isBrightTheme;
	}

	get isPaywithMode(): boolean {
		return !!this.session?.isPayWithCheckout;
	}

	iframeLinkOrHtml?: string;

	showPaymentMethodsPage$ = this.__session$.pipe(
		map(session => session.onlyCreditCardPaymentMethod
			|| session.singlePaymentProvider
			|| session.singlePaymentMethod && session.singlePaymentProvider
			|| session.singlePaymentMethod && session.paymentMethods.filter(v => v.type === session.singlePaymentMethod).length === 1
			? false
			: session.paymentMethods.length > 1 || session.paymentMethods[0]?.type.isApm && session.amountLock && this.isEmbedded && session.paymentMethods[0].open_in_new_window),
		startWith(false),
	);

	showPaymentMethodsPage!: boolean;

	private readonly __embedded$ = new OptionalBehaviorSubject<EmbeddedData>();

	embedded$ = this.__embedded$.asObservable();

	get embedded(): EmbeddedData | null {
		return this.__embedded$.value ?? null;
	}

	private _launchButtonMode: CheckoutLaunchButtonMode | null = null;

	get launchButtonMode(): CheckoutLaunchButtonMode | null {
		const launchButtonMode = this._launchButtonMode ?? this.session?.buttonMode ?? this.embedded?.buttonMode;

		if (launchButtonMode)
			return launchButtonMode;

		const storedLaunchedButtonMode = this.__getStoredItem(this.__getLaunchButtonModeStoreKey());

		if (storedLaunchedButtonMode)
			return (this._launchButtonMode = CheckoutLaunchButtonMode.parse(storedLaunchedButtonMode));

		return null;
	}

	get isMerchantAdmin(): boolean {
		return !!this.embedded?.isMerchantAdmin;
	}

	get windowContext(): CheckoutWindowContext {
		if (this.isStandalone)
			return window.menubar.visible ? CheckoutWindowContext.tab : CheckoutWindowContext.popup;

		return CheckoutWindowContext.iframe;
	}

	transaction!: TransactionInfo | null;

	paymentMethod!: IPaymentMethod;

	userEmail?: string;

	returnUrl?: string;

	userDetailsForm!: FormGroup;

	isUserDetailsInvalidOnInit!: boolean;

	openTab: Window | null = null;

	paymentProcessingPageUrl: string | null = null;

	private readonly __error$ = new BehaviorSubject<BpError | string | null>(null);

	error$ = this.__error$.asObservable();

	get error(): BpError | string | null {
		return this.__error$.value;
	}

	private __paymentRequest: RequestDeposit | null = null;

	isEmbedded = isEmbedded();

	isStandalone = !this.isEmbedded;

	isNestedInsideAnotherCheckout = this.__checkIsNestedInsideAnotherCheckout();

	hasDeposited = false;

	browserDetails: IBrowserDetails = {
		java_enabled: `${ window.navigator.javaEnabled() }`,
		java_script_enabled: `${ true }`,
		color_depth: screen.colorDepth,
		screen_height: screen.availHeight,
		screen_width: screen.availWidth,
		time_zone: (new Date())
			.getTimezoneOffset()
			.toString(),
		user_agent: navigator.userAgent,
		language: navigator.language,
	};

	referer: string | null = null;

	private readonly __appReady$ = new AsyncVoidSubject();

	appReady$ = this.__appReady$.asObservable();

	loadingPaymentMethodDependenciesSubscription = Subscription.EMPTY;

	loadingPaymentMethodDependencies!: IPaymentMethod | null;

	isBlox = this.initialUrl.includes('blox');

	get initialUrl(): string {
		return AppService.initialUrl;
	}

	private readonly __fontsLoadingFinished$ = new BehaviorSubject<boolean>(false);

	fontsLoadingFinished$ = this.__fontsLoadingFinished$.asObservable();

	private __hasAlertBeforeunload = false;

	private readonly __checkoutKey$ = new BehaviorSubject<string | null>(null);

	private readonly __rum$ = new BehaviorSubject<{ checkoutKey?: string | null; enabled: boolean }>({ enabled: false });

	constructor() {
		TelemetryService.log('Initial URL', this.initialUrl);

		this.__httpConfigService.setHttpHeader('x-fe-initial-url', trimLogHeaderUrl(encodeURI(this.initialUrl)));

		void this.__whenRumEnabledLaunchLogrocket();

		this.__momentTimezoneService.setGuessedTimezone();

		this.__httpConfigService.setHttpHeader(JSON_NAMING_STRATEGY_HEADER, JsonNamingStrategy.SnakeCase);

		this.__embedded$
			.pipe(first())
			.subscribe(embedded => {
				TelemetryService.log('Embedded data', omit(embedded.dto, [ 'depositToken', 'paymentToken' ]));

				TelemetryService.log(`Has Query Param Payment Token: ${ !!embedded.paymentToken }`);

				if (embedded.useLocalBackend)
					LocalBackendState.setAndReloadPage(embedded.useLocalBackend);

				if (!!embedded.useMockBackend || !!embedded.checkoutKey && isMockSession(embedded.checkoutKey) || isMockSession(embedded.checkoutSessionId))
					MockedBackendState.setAndReloadPage(true);

				this.__storeLaunchButtonModeState();
			});

		this.__session$
			.pipe(first())
			.subscribe(session => {
				this.setCheckoutKey(session.checkoutKey);

				void TelemetryService.log(
					'Cashier session has been set:',
					omit(session.dto, 'access_token'),
				);

				this.__paymentOptionInstancesManager.init(this);
			});

		this.__session$
			.subscribe(session => {
				this.__httpConfigService.setAuthorizationHeader(session.accessToken!.token);

				this.embedded!.checkoutSessionId = this.embedded!.dto.checkoutSessionId = session.checkoutSessionId = session.id;

				this.__storeLaunchButtonModeState();
			});

		merge(
			this.__embedded$,
			this.__session$,
		)
			.subscribe(config => {
				this.__updateTelemetryTags(config);

				this.__languagesService
					.use(config.language ?? this.__languagesService.getSupportedBrowserLanguage())
					// eslint-disable-next-line rxjs/no-nested-subscribe
					.subscribe();
			});

		this.showPaymentMethodsPage$
			.subscribe(showPaymentMethodsPage => (this.showPaymentMethodsPage = showPaymentMethodsPage));

		this.__environment.backendDeploymentVersions$
			.subscribe(backendDeploymentVersions => void this.__telemetry.setTags(backendDeploymentVersions));

		window.bpAppService = this;
	}

	private __storeLaunchButtonModeState(): void {
		if (this.embedded?.checkoutSessionId && this.embedded.buttonMode)
			localStorage.setItem(this.__getLaunchButtonModeStoreKey(), this.embedded.buttonMode.name);
	}

	private __getLaunchButtonModeStoreKey(): string {
		return `launch_button_mode:${ this.embedded!.checkoutSessionId }`;
	}

	setCheckoutKey(key: string | undefined): void {
		if (!key || this.__checkoutKey$.value)
			return;

		this.__checkoutKey$.next(key);

		this.__hostNotifier.setCheckoutKey(key);
	}

	markAppReady(): void {
		TelemetryService.log('App ready');

		this.__appReady$.complete();
	}

	getTabWindowName(): string {
		return `[bp][context:${ CheckoutWindowContext.tab }][checkoutSessionId:${ this.session!.id }]`;
	}

	async handleEncryptedPaymentToken(): Promise<void> {
		TelemetryService.log('No payment request in local storage, try to decrypt payment token.');

		let paymentTokenToDecrypt: string | null = null;
		const queryParamsPaymentToken = paymentTokenToDecrypt = this.embedded!.paymentToken ?? null;

		if (queryParamsPaymentToken)
			await this.__storePaymentToken(queryParamsPaymentToken);

		paymentTokenToDecrypt ||= await firstValueFrom(
			this.__paymentsApi.fetchEncryptedPaymentToken(this.embedded!.checkoutSessionId),
		);

		if (!paymentTokenToDecrypt)
			throw new Error('No encrypted payment token found');

		let paymentRequest: RequestDeposit;

		try {
			paymentRequest = <RequestDeposit>JSON.parse(decode(paymentTokenToDecrypt));
		} catch {
			paymentRequest = await this.__cipherService.decrypt<RequestDeposit>(paymentTokenToDecrypt);
		}

		this.storePaymentRequestBody(paymentRequest);
	}

	setEmbedded(embedded: EmbeddedData): void {
		this.__embedded$.next(embedded);
	}

	newSession(session: CheckoutSession): void {
		this.hasDeposited = false;

		this.__session$.next(session);
	}

	setError(error: BpError | string | null): void {
		this.__error$.next(
			error,
		);
	}

	hasDoneDeposit(): void {
		this.removeAlertBeforeUnload();

		this.hasDeposited = true;
	}

	navigate(commands: any[], extras?: NavigationExtras): void {
		void this.__router.navigate(commands, { skipLocationChange: true, ...extras });
	}

	navigateByUrl(url: UrlTree | string, extras?: NavigationExtras): void {
		void this.__router.navigateByUrl(url, { skipLocationChange: true, ...extras });
	}

	navigateTo3dSecure(htmlOrLink: string, provider?: string): void {
		if (htmlOrLink.startsWith('http://')) {
			this.navigate([ '/error' ]);

			return;
		}

		this.iframeLinkOrHtml = htmlOrLink;

		this.navigate([
			'/payments/3d-secure',
			{
				provider: provider ?? '',
			},
		]);
	}

	navigateToApmIframe({ htmlOrLink, provider }: { htmlOrLink: string; provider?: string }): void {
		if (htmlOrLink.startsWith('http://')) {
			this.navigate([ '/error' ]);

			return;
		}

		this.iframeLinkOrHtml = htmlOrLink;

		this.navigate([
			'/payments/iframe',
			{
				provider: provider ?? '',
				hideBackBtn: true,
			},
		]);
	}

	navigateToHome(): void {
		this.navigateByUrl('/');
	}

	navigatePageTo3dParty(htmlOrLink: string): void {
		if (isURL(htmlOrLink))
			this.changeWindowLocationTo(htmlOrLink);
		else
			documentWrite(window.document, htmlOrLink);
	}

	async buildUrlWithEmbedParamsAndDepositRequest(
		path: string,
		paymentRequest: RequestDeposit,
		{ encryptPayload }: { encryptPayload?: boolean } = { encryptPayload: true },
	): Promise<string> {
		const url = new URL(path, document.baseURI);

		const queryParams = new URLSearchParams(<Record<string, string>> omitBy(this.embedded?.dto, isNil));

		queryParams.delete('id');

		queryParams.delete('splashScreenImageUrl');

		queryParams.delete('$$backUrl');

		const paymentToken = encryptPayload
			? await this.__encryptPaymentRequest(paymentRequest)
			: encodeURL(JSON.stringify(paymentRequest));

		queryParams.set('paymentToken', paymentToken);

		if (MockedBackendState.isActive)
			queryParams.set('useMockBackend', 'true');

		url.search = queryParams.toString();

		return url.toString();
	}

	private async __encryptPaymentRequest(paymentRequest: RequestDeposit): Promise<string> {
		return this.__cipherService.encrypt(omitBy(paymentRequest, isNil));
	}

	storePaymentRequestBody(paymentRequest: RequestDeposit): void {
		logNonCriticalPaymentRequestProperties('Set payment request', paymentRequest);

		this.__paymentRequest = paymentRequest;
		const paymentRequestAsString = JSON.stringify(paymentRequest);

		try {
			localStorage.setItem(this.__derivePaymentRequestStoreKey(), paymentRequestAsString);
		} catch {
			// the local storage might be unavailable due to the user's privacy settings
		}
	}

	getPaymentRequestBody(): RequestDeposit | null {
		if (IS_MPI) {
			return <RequestDeposit> {
				type: PaymentOptionType.creditCard.snakeCase,
				additional_data: this.browserDetails,
				browser_details: this.browserDetails,
			};
		}

		this.__paymentRequest ??= this.__getStoredPaymentRequest();

		if (this.__paymentRequest)
			logNonCriticalPaymentRequestProperties('Local payment request', this.__paymentRequest);

		return this.__paymentRequest;
	}

	private __getStoredPaymentRequest(): RequestDeposit | null {
		return JSON.parse(
			this.__getStoredItem(this.__derivePaymentRequestStoreKey())
			?? this.__getStoredItem(this.__deriveDepositRequestStoreKey())
			?? 'null',
		);
	}

	clearPaymentRequestFromStorage(): void {
		this.__removeStoredItem(this.__derivePaymentRequestStoreKey());

		// TODO: Remove after prod release
		this.__removeStoredItem(this.__deriveDepositRequestStoreKey());
	}

	private __derivePaymentRequestStoreKey(): string {
		return `paymentRequest:${ this.embedded!.checkoutSessionId || this.session!.id }`;
	}

	/** TODO: Remove after prod release */
	private __deriveDepositRequestStoreKey(): string {
		return `depositRequest:${ this.embedded!.checkoutSessionId || this.session!.id }`;
	}

	private __removeStoredItem(key: string): void {
		try {
			void localStorage.removeItem(key);
		} catch {
			// the local storage might be unavailable due to the user's privacy settings
		}
	}

	private __getStoredItem(key: string): string | null {
		try {
			return localStorage.getItem(key);
		} catch {
			// the local storage might be unavailable due to the user's privacy settings
			return null;
		}
	}

	redirectHostTo(url: string): void {
		if (this.isEmbedded || this.launchButtonMode?.isPopupWindow)
			this.__hostNotifier.redirectHostPage(url);
		else
			this.changeWindowLocationTo(url);
	}

	changeWindowLocationTo(url: string): void {
		this.removeAlertBeforeUnload();

		if (url.startsWith(window.location.origin))
			this.navigateByUrl(url.replace(window.location.origin, ''));
		else
			window.location.href = url;
	}

	alertBeforeUnload(): void {
		if (this.__hasAlertBeforeunload)
			return;

		window.addEventListener('beforeunload', this.__alertBeforeUnloadHandler);

		this.__hasAlertBeforeunload = true;
	}

	removeAlertBeforeUnload(): void {
		if (!this.__hasAlertBeforeunload)
			return;

		window.removeEventListener('beforeunload', this.__alertBeforeUnloadHandler);

		this.__hasAlertBeforeunload = false;
	}

	private readonly __alertBeforeUnloadHandler = (event: {
		preventDefault: () => void;
		returnValue: string;
	}): void => {
		// Cancel the event
		event.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown

		// Chrome requires returnValue to be set
		event.returnValue = '';
	};

	loadBanks(method: IPaymentMethod): Observable<void> {
		return new Observable(observer => {
			if (method.banks) {
				observer.next();

				observer.complete();

				return;
			}

			const subscription = this.__paymentsApi
				.getBanksInfo(method.provider)
				.subscribe({
					next: banks => {
						this.setBanksToMethod(method, banks);

						observer.next();

						observer.complete();
					},
					error: (error: unknown) => {
						this.setError(BpError.fromUnknown(error));

						void this.__router.navigate([ '/error', { canGoHome: true }]);

						observer.error();
					},
				});

			return () => void subscription.unsubscribe();
		});

	}

	navigateToPaymentOption(method: IPaymentMethod): void {
		this.loadingPaymentMethodDependenciesSubscription.unsubscribe();

		this.loadingPaymentMethodDependencies = null;

		if (method.type.isPaywith)
			this.setCheckoutType(CheckoutType.paywith);

		if (method.has_banks && !method.subtype.isEft) {
			this.loadingPaymentMethodDependencies = method;

			this.loadingPaymentMethodDependenciesSubscription = this.loadBanks(method)
				.subscribe({
					next: () => void this.navigate(this.getPaymentPageRouteCommands(method)),
					error: () => {
						// suppress throwing up since was handled in load banks
					},
					complete: () => (this.loadingPaymentMethodDependencies = null),
				});
		} else if (method.type === PaymentOptionType.externalLink)
			this.__openNewWindowOnEmbedOrChangeCurrentLocation(method.link);
		else
			this.navigate(this.getPaymentPageRouteCommands(method));
	}

	navigateAccordingFinalTransactionStatus(transaction: Transaction | null): void {
		this.setError(transaction?.isDeclined
			? new BpError({
				status: ResponseStatusCode.TransactionDeclined,
				messages: [{
					message: lowerCase(transaction.status),
				}],
			})
			: null);

		this.navigate(this.__getTransactionStatusCommands(transaction));
	}

	getAppearance(): MatLegacyFormFieldAppearance {
		return this.embedded?.isJamesAllen || this.embedded?.isJamesAllenBluennile || this.embedded?.isHighlowV2 || this.embedded?.isDavidShields || this.isBrightTheme
			? 'outline'
			: 'standard';
	}

	getHideRequiredMarker(): boolean {
		return false;
	}

	getFloatLabelType(): LegacyFloatLabelType {
		return this.session?.isPayWithCheckout || this.isBrightTheme ? 'auto' : 'always';
	}

	setBanksToMethod(method: IPaymentMethod, banks: IBank[]): void {
		banks.forEach(bank => {
			const assetLogoUrl = `assets/images/banks/${ method.provider }/${ bank.bank_code.toLowerCase() }`;

			const onlyAssetLogoUrl = method.provider === 'directa24' && [ 'VI', 'VD', 'MC', 'MD' ].includes(bank.bank_code) || !bank.image_url;

			bank.logo = {
				urls: onlyAssetLogoUrl ? [ assetLogoUrl ] : [ bank.image_url!, assetLogoUrl ],
			};

			// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
			bank.currency = bank.currency
				? new FiatCurrency(bank.currency)
				: this.session!.currency;

			if (bank.currency.code === method.currency_rate?.target_currency)
				bank.currency = new FiatCurrency(method.currency_rate.base_currency);

			if (!isEmpty(bank.payment_types)) {
				method.banks_payment_types = uniq([
					...(method.banks_payment_types ?? []),
					...bank.payment_types ?? [],
				]);
			}
		});

		method.banks = banks;

		if (method.banks_payment_types) {
			method.banks_by_payment_type = new Map(
				method.banks_payment_types.map(paymentType => [
					paymentType,
					banks.filter(bank => bank.payment_types?.includes(paymentType)),
				]),
			);
		}
	}

	private __getTransactionStatusCommands(transaction: Transaction | null): string[] {
		const root = '/status';

		if (transaction?.isApproved || transaction?.isDeclined) {
			if (transaction.isApproved)
				return [ root, 'card-success' ];
			else if (transaction.isDeclined)
				return [ root, 'card-declined' ];
		} else
			return [ '/error' ];

		return [ '/error' ];
	}

	private __openNewWindowOnEmbedOrChangeCurrentLocation(url: string): void {
		if (this.isEmbedded)
			window.open(url, '_blank', 'noreferrer');
		else
			window.location.href = url;
	}

	getPaymentPageRouteCommands(method: IPaymentMethod): string[] {
		const paymentOptionsPage = [ '/payments' ];
		const paymentOptionBaseSegments = [ ...paymentOptionsPage, method.type.name ];

		if (method.type.isApm && method.process_as_credit_card) {
			return [
				...paymentOptionsPage,
				PaymentOptionType.creditCard.name,
				PaymentOptionType.apm.name,
				method.provider,
				method.currencyCode,
			];
		}

		if (method.type.isBanks && method.banks_payment_types) {
			return [
				...paymentOptionsPage,
				'banks-payment-types',
				method.provider,
				method.subtype.name,
			];
		}

		if (method.subtype.isEft && !!method.banks) {
			return [
				...paymentOptionsPage,
				PaymentOptionType.banks.name,
				method.provider,
				method.subtype.name,
			];
		}

		switch (method.type) {
			case PaymentOptionType.creditCard:
				return [
					...paymentOptionBaseSegments,
					method.provider,
					method.currencyCode,
				];

			case PaymentOptionType.crypto:
				return [
					...paymentOptionBaseSegments,
					method.provider,
					method.currencyCode,
				];

			case PaymentOptionType.apm:
				return [
					...paymentOptionBaseSegments,
					method.provider,
					method.subtype.name,
					method.currencyCode,
				];

			case PaymentOptionType.banks:
				return [
					...paymentOptionBaseSegments,
					method.provider,
					method.subtype.name,
				];

			case PaymentOptionType.externalLink:
				return paymentOptionsPage;

			default:
				return paymentOptionBaseSegments;
		}
	}

	setCheckoutType(type: CheckoutType): void {
		this.__session$.next(new CheckoutSession({
			...this.session!.dto,
			checkoutType: type.isPaywith ? CheckoutType.paywith.name : this.session!.initialCheckoutType.name,
			theme: type.isPaywith ? CheckoutTheme.bright.name : this.session!.initialTheme.name,
			language: this.__languagesService.currentLanguage?.iso,
		}));
	}

	getPaymentCardTokens(provider: string): Observable<SavedPaymentCardToken[] | null> {
		return this.__sessionApi.get(this.session!.id).pipe(
			startWith(this.session!),
			map(session => session.paymentMethods
				.find(method => method.type === PaymentOptionType.creditCard && method.provider === provider)?.credit_card_tokens ?? null),
		);
	}

	private async __storePaymentToken(paymentToken: string): Promise<void> {
		TelemetryService.log('Storing payment token');

		try {
			await firstValueFrom(
				this.__paymentsApi
					.storeEncryptedPaymentToken(this.embedded!.checkoutSessionId, paymentToken),
			);

			TelemetryService.log('Stored payment token success');
		} catch (error: unknown) {
			TelemetryService.log('Error while storing payment details as encrypted token');

			TelemetryService.captureError(error);
		}
	}

	telemetryIdentifyUser(userId: string): void {
		this.__telemetry.identifyUser({
			id: userId,
			email: userId.includes('@') ? userId : undefined,
		});
	}

	private __updateTelemetryTags(config: CheckoutSession | EmbeddedData): void {
		// eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
		const userId = config.user || config.email.value || this.__geolocationService.info.ip;

		if (userId)
			this.telemetryIdentifyUser(userId);

		this.__telemetry.setTags({
			referer: this.referer,
			checkoutBrowserContext: this.isEmbedded ? 'embedded' : 'standalone',
			checkoutWindowContext: this.windowContext.name,
			buttonMode: config.buttonMode?.name,
			isLauncherInitiated: !!config.isLauncherInitiated,
			isLauncherEmbeddedInIframe: !!config.isLauncherEmbeddedInIframe,
			checkoutId: config.checkoutKey,
			merchantOrderId: config.orderId,
			presetId: config.presetId,
			checkoutSessionId: config.checkoutSessionId,
			trackingId: config.trackingId,
			isMpi: IS_MPI,
		});
	}

	setRumState(enabled: boolean, checkoutKey: string | null): void {
		this.__rum$.next({ enabled, checkoutKey });
	}

	private __whenRumEnabledLaunchLogrocket(): void {
		this.__rum$
			.pipe(first(({ enabled, checkoutKey }) => enabled && !checkoutKey))
			.subscribe(() => void TelemetryService.initLogrocketReporterOnDemand());

		combineLatest([
			this.__rum$,
			this.__checkoutKey$,
		])
			.pipe(first(([ rum, checkoutKey ]) => rum.enabled && rum.checkoutKey === checkoutKey))
			.subscribe(() => void TelemetryService.initLogrocketReporterOnDemand());
	}

	private __checkIsNestedInsideAnotherCheckout(): boolean {
		try {
			return window.parent !== window.self && !!window.parent.origin;
		} catch {
			return false;
		}
	}

	async onceFontsLoaded(): Promise<void> {
		return firstValueFrom(this.fontsLoadingFinished$.pipe(
			first(fontsLoaded => fontsLoaded),
			map(() => void 0),
		));
	}

	loadFonts(urls: string[]): void {
		const urlsToLoad = urls.filter(
			stylesheetUrl => !document.querySelector(`link[href="${ stylesheetUrl }"]`),
		);

		if (urlsToLoad.length === 0)
			return;

		let loadedStylesheetsCount = 0;

		this.__fontsLoadingFinished$.next(false);

		urlsToLoad.forEach(stylesheetUrl => {
			const link = document.createElement('link');

			link.rel = 'stylesheet';

			link.href = stylesheetUrl;

			link.addEventListener('load', () => {
				loadedStylesheetsCount++;

				if (loadedStylesheetsCount === urlsToLoad.length)
					this.__fontsLoadingFinished$.next(true);
			});

			link.addEventListener('error', () => {
				if (Math.random() < 0.05) {
					this.__geolocationService.info$
						.pipe(first())
						.subscribe(info => void this.__telemetry.captureMessage(`${ info.country }: Failed to load google font ${ stylesheetUrl }`));
				}

				this.__fontsLoadingFinished$.next(true);
			});

			document.querySelectorAll('head')[0].append(link);
		});
	}

	throw() {
		throw new Error('Debug sentry replay');
	}

	isRegularMerchant(): boolean {
		// if checkout on custom hostname
		if ([ 'bridgerpay', 'bridgeros' ].every(domain => !window.location.hostname.includes(domain)))
			return false;

		const [ subdomain ] = window.location.hostname.split('.');

		// enterprise merchants sometimes given prefixed subdomains eg 'ht-checkout', 'ht-mpi-checkout'
		return subdomain === 'checkout' || subdomain === 'mpi-checkout';
	}
}

declare global {

	interface Window {
		bpAppService: {
			navigateByUrl: (pathname: string) => void;
		};
	}
}
