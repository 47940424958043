import { isEmpty } from 'lodash-es';
import tippy, { animateFill, Placement as TippyPlacement, Instance as Tippy, Props as TippyConfig } from 'tippy.js';
// eslint-disable-next-line @typescript-eslint/naming-convention
import * as Popper from '@popperjs/core';

import { OnDestroy, Directive, ElementRef, Input, SecurityContext, InjectionToken, inject, Inject, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { attrBoolValue, cancelIdleCallback, requestIdleCallback } from '@bp/shared/utilities/core';

import { ZoneService } from '@bp/frontend/rxjs';
import { OnChanges, SimpleChanges } from '@bp/frontend/models/core';

type RequiredTippyConfig = Pick<TippyConfig, 'content' | 'hideOnClick' | 'placement' | 'showOnCreate'>;

// Default theme. Note that animateFill plugin is used with this theme, and it doesn't support arrow, so
// related prop will have no effect if this plugin is enabled.
const MATERIAL_THEME = 'material';

export interface ITooltipConfiguration {
	placement?: TippyPlacement;
	positioningStrategy?: Popper.PositioningStrategy;
}

export const TOOLTIP_CONFIGURATION_TOKEN = new InjectionToken<ITooltipConfiguration>(
	'TOOLTIP_CONFIGURATION', { factory: () => ({}) },
);

/**
 * Tooltip directive based on Tippy.js
 *
 * @link https://atomiks.github.io/tippyjs/v6/getting-started/
 */
@Directive({
	selector: '[bpTooltip]',
})
export class TooltipDirective implements OnChanges, OnInit, OnDestroy, ITooltipConfiguration {

	private readonly __host = inject(ElementRef);

	private readonly __zoneService = inject(ZoneService);

	private readonly __sanitizer = inject(DomSanitizer);

	@Input('bpTooltip')
	content?: string | '' | null = null;

	@Input('bpTooltipPlacement')
	placement: TippyPlacement = 'top';

	@Input('bpTooltipHideOnClick')
	hideOnClick = true;

	@Input('bpTooltipShowOnCreate')
	showOnCreate = false;

	@Input('bpTooltipAppendToParent')
	appendToParent: boolean | '' = false;

	// Always disabled with material theme.
	@Input('bpTooltipArrow')
	arrow = false;

	@Input('bpTooltipTheme')
	theme = MATERIAL_THEME;

	/*
	 * @link https://atomiks.github.io/tippyjs/v6/faq/#my-tooltip-appears-cut-off-or-is-not-showing-at-all
	 * @link https://popper.js.org/docs/v2/constructors/#strategy
	 */
	@Input('bpPositioningStrategy')
	positioningStrategy: Popper.PositioningStrategy = 'absolute';

	private __tippy: Tippy | null = null;

	private __enabled = true;

	private get __disabled(): boolean {
		return !this.__enabled;
	}

	private __destroyed = false;

	private __awaitingTaskId!: number;

	constructor(@Inject(TOOLTIP_CONFIGURATION_TOKEN) baseConfiguration: ITooltipConfiguration) {
		Object.assign(this, baseConfiguration);
	}

	ngOnInit(): void {
		this.appendToParent = attrBoolValue(this.appendToParent);
	}

	ngOnChanges(_changes: SimpleChanges<this>): void {
		this._handleTooltipConfigChange();
	}

	ngOnDestroy(): void {
		this.__destroyed = true;

		this._destroyTippy();
	}

	enable(): void {
		this.__enabled = true;

		this._handleTooltipConfigChange();
	}

	disable(): void {
		this.__enabled = false;

		this._handleTooltipConfigChange();
	}

	private _handleTooltipConfigChange(): void {
		this._runOutsideWhenIdle(() => {
			if (isEmpty(this.content) || this.__disabled) {
				this._destroyTippy();

				return;
			}

			const config: RequiredTippyConfig = {
				content: this._getSanitizedContent(),
				placement: this.placement,
				hideOnClick: this.hideOnClick,
				showOnCreate: this.showOnCreate,
			};

			if (this.__tippy)
				this._updateTippy(config);
			else
				this._createTippy(config);
		});
	}

	private _getSanitizedContent(): string {
		return this.content
			? this.__sanitizer.sanitize(SecurityContext.HTML, this.content)!
			: '';
	}

	private _createTippy(config: Partial<TippyConfig> & RequiredTippyConfig): void {
		if (this.__destroyed)
			return;

		this.__tippy = tippy(
			<HTMLElement> this.__host.nativeElement,
			{
				ignoreAttributes: true,
				arrow: this.arrow,
				theme: this.theme,
				animateFill: this.theme === MATERIAL_THEME,
				plugins: [ animateFill ],
				allowHTML: true,
				appendTo: this.appendToParent ? 'parent' : document.body,
				popperOptions: {
					strategy: this.positioningStrategy,
				},
				...config,
			},
		);
	}

	private _updateTippy(config: Partial<TippyConfig>): void {
		if (this.__destroyed)
			return;

		this.__tippy?.setProps(config);
	}

	private _destroyTippy(): void {
		this.__tippy?.destroy();

		this.__tippy = null;
	}

	private _runOutsideWhenIdle(task: () => void): void {

		/*
		 * If a new task came but the previous hasn't started or been finished we cancel it since we don't need
		 * the task done anymore
		 */
		cancelIdleCallback(this.__awaitingTaskId);

		this.__awaitingTaskId = this.__zoneService.runOutsideAngular(
			() => requestIdleCallback(task, { timeout: 500 }),
		);
	}
}
