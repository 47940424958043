/* eslint-disable no-global-assign,@typescript-eslint/no-unsafe-assignment */
import { createServer } from 'miragejs';
import type { Server } from 'miragejs';
import { isArray } from 'lodash-es';
import { ServerConfig } from 'miragejs/server';
import { AnyFactories, AnyModels } from 'miragejs/-types';

import { MockedBackendState } from '@bp/frontend/services/persistent-state-keepers';
import { IApiMockPlugin } from '@bp/frontend/services/core';

export type MirageServerConfig = Parameters<typeof createServer>[ 0 ];

export abstract class ApiMockPlugin implements IApiMockPlugin {

	static isDemoMode = MockedBackendState.isDemoMode;

	private static readonly _mirageJsConfigs = new Set<MirageServerConfig>();

	private static _server?: Server;

	private static _mergeSubConfigs(
		configProperty: keyof MirageServerConfig,
	): ServerConfig<AnyModels, AnyFactories> {
		return [ ...ApiMockPlugin._mirageJsConfigs ]

			.reduce(
				(mergedConfig, config) => ({
					...mergedConfig,
					...config[configProperty],
				}),
				{},
			);
	}

	private readonly _mirageJsConfig?: MirageServerConfig;

	constructor(config: MirageServerConfig) {
		this._mirageJsConfig = config;

		ApiMockPlugin._mirageJsConfigs.add(config);
	}

	shutdown(): void {
		ApiMockPlugin._mirageJsConfigs.clear();

		ApiMockPlugin._server?.shutdown();

		ApiMockPlugin._server = undefined;
	}

	init(urlPrefix: string): void {
		ApiMockPlugin._server?.shutdown();

		ApiMockPlugin._server = this.__createServer(urlPrefix);

		this._rollbackPretenderPatchToPassthroughFirebaseRequests();
	}

	// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
	private __createServer(urlPrefix: string) {
		this._assertConfigIsPresent(this._mirageJsConfig);

		/*
		 * MirageJs doesn't allow multiple servers run at the same time, and there is no way to import configs
		 * to use different files for storing configs
		 */
		return createServer({

			models: <any> ApiMockPlugin._mergeSubConfigs('models'),

			fixtures: ApiMockPlugin._mergeSubConfigs('fixtures'),

			seeds(server) {
				ApiMockPlugin._mirageJsConfigs.forEach(config => config.seeds && void config.seeds(server));
			},

			routes() {
				this.timing = MockedBackendState.isDemoMode ? 150 : 500;

				if (sessionStorage.getItem('playwright'))
					this.timing = 0;

				this.urlPrefix = urlPrefix;

				const namespaces: string[] = [ ...ApiMockPlugin._mirageJsConfigs ]
					.flatMap(config => {
						// eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
						const apiPluginNamespace = <string[] | string ><unknown> config.routes!.call(this);
						const apiPluginNamespaces = isArray(apiPluginNamespace)
							? apiPluginNamespace
							: [ apiPluginNamespace ];

						if (!apiPluginNamespaces.every(
							pluginNamespace => pluginNamespace.startsWith('/') || pluginNamespace.startsWith('http'),
						)) {
							throw new Error(
								'ApiMockPlugin inheritor\'s routes function must return namespace that starts with `/` or absolute URL',
							);
						}

						return apiPluginNamespaces.map(
							pluginNamespace => pluginNamespace.startsWith('/')
								? `${ urlPrefix }${ pluginNamespace }`
								: pluginNamespace,
						);
					});

				// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
				(<any> this.pretender).disableUnhandled = true;

				this.passthrough(request => namespaces.every(namespace => !request.url.includes(namespace)));
			},
		});
	}

	private _assertConfigIsPresent(config: any): asserts config is MirageServerConfig {
		if (config)
			return;

		throw new Error('Method not implemented.');
	}

	private _rollbackPretenderPatchToPassthroughFirebaseRequests(): void {
		// @ts-expect-error Rolling back to enable firebase requests
		fetch = window.__zone_patched__fetch ?? fetch;

		// @ts-expect-error Rolling back to enable firebase requests
		Request = window.__zone_patched__Request ?? Request;
	}

}
