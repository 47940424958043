<h2 mat-dialog-title>Unsaved Changes</h2>

<mat-dialog-content>
	<div>Are you sure that you want to leave? There are unsaved changes. If you leave, your changes will be lost.</div>
</mat-dialog-content>

<mat-dialog-actions>
	<button mat-stroked-button class="cancel mr-auto" type="button" color="primary" [mat-dialog-close]="false">
		Stay Here
	</button>

	<button mat-stroked-button type="button" color="primary" [mat-dialog-close]="true">Discard Changes</button>
</mat-dialog-actions>
