import { difference, fromPairs, get, isEmpty, isNil, set, transform } from 'lodash-es';
import { BehaviorSubject, combineLatest, merge } from 'rxjs';
import { auditTime, debounceTime, filter, map, mergeMap, observeOn, pairwise, startWith, switchMap } from 'rxjs/operators';

import {
	AfterContentInit, ChangeDetectionStrategy, Component, ContentChildren, Directive, Input, Optional, Output,
	QueryList, SkipSelf
} from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';

import { observeQueryListChanges } from '@bp/shared/utilities/core';
import { Dictionary } from '@bp/shared/typings';

import { Destroyable, takeUntilDestroyed } from '@bp/frontend/models/common';
import { UrlHelper } from '@bp/frontend/utilities/common';
import { BpScheduler, OptionalBehaviorSubject } from '@bp/frontend/rxjs';
import { OnChanges, SimpleChanges } from '@bp/frontend/models/core';

import { FORM_FIELD_DEFAULT_OPTIONS, IFormFieldDefaultOptions } from '../form-field-default-options';

import { FilterControlDirective } from './filter-control.directive';
import { FILTER_CONTROL_OPTIONS_TOKEN } from './filter-control-options.injection-token';

export type FilterValue = Record<string, unknown>;

type DefaultsStringed = Dictionary<string | undefined>;

@Directive()
export class FilterHostDirective {
	filter!: FilterComponent;
}

@Component({
	selector: 'bp-filter',
	template: '<ng-content />',
	styleUrls: [ './filter.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: FORM_FIELD_DEFAULT_OPTIONS,
			deps: [[ new SkipSelf(), FORM_FIELD_DEFAULT_OPTIONS ], [ new Optional(), FILTER_CONTROL_OPTIONS_TOKEN ]],
			useFactory: (formFieldDefaultOptions: IFormFieldDefaultOptions, filterControlOptions?: IFormFieldDefaultOptions): IFormFieldDefaultOptions => ({
				...formFieldDefaultOptions,
				...filterControlOptions,
			}),
		},
	],
	host: {
		class: 'bp-filter',
	},
})
export class FilterComponent<T = FilterValue> extends Destroyable implements OnChanges, AfterContentInit {

	@Input() except: string[] = [];

	@Input() type: 'matrix' | 'query' = 'matrix';

	@Input() defaults: T = <T> {};

	private readonly _value$ = new OptionalBehaviorSubject<T>();

	@Output('value') readonly value$ = this._value$.asObservable();

	get value(): T {
		return this._value$.value ?? <T>{};
	}

	get nonClearableControlsCount(): number {
		return this._controlsQuery?.filter(control => !control.isClearable).length ?? 0;
	}

	empty!: boolean;

	@ContentChildren(FilterControlDirective, { descendants: true })
	private readonly _controlsQuery?: QueryList<FilterControlDirective>;

	private readonly _filterControls$ = new BehaviorSubject<FilterControlDirective[]>([]);

	private readonly _defaults$ = new BehaviorSubject<T>(<T> {});

	private readonly _defaultsStringed$ = new BehaviorSubject(<DefaultsStringed> {});

	constructor(
		private readonly _router: Router,
		private readonly _route: ActivatedRoute,
	) {
		super();

		this._updateEmptyPropertyOnValueChange();
	}

	ngOnChanges({ defaults }: SimpleChanges<this>): void {
		defaults && this._defaults$.next(this.defaults);
	}

	ngAfterContentInit(): void {
		this._updateFilterControlsOnDOMChange();

		this._updateDefaultsStringedOnControlsOrDefaultsChange();

		this._updateFilterControlsOnRelevantRouteParamsChange();

		this._updateFilterValueOnFilterControlsValuesChange();

		this._updateRouteParamsInURLOnFilterControlsValuesChange();

		this._removeRouteParamsFromURLOnFilterControlsRemoval();
	}

	clear(): void {
		this._controlsQuery
			?.filter(control => control.isClearable)
			.forEach(control => void control.setValue(null));
	}

	private _updateEmptyPropertyOnValueChange(): void {
		this.value$
			.pipe(takeUntilDestroyed(this))
			.subscribe(value => (this.empty = isEmpty(value)));
	}

	private _updateFilterControlsOnDOMChange(): void {
		observeQueryListChanges(this._controlsQuery!)
			.pipe(takeUntilDestroyed(this))
			.subscribe(controls => void this._filterControls$.next(controls));
	}

	private _removeRouteParamsFromURLOnFilterControlsRemoval(): void {
		this._filterControls$
			.pipe(
				pairwise(),
				map(([ previous, current ]) => difference(previous, current)),
				filter(v => v.length > 0),
				takeUntilDestroyed(this),
			)
			.subscribe(deletedControls => {
				const routeParams = this._getRouteParamsAccordingToFilterRouteParamsType();

				deletedControls.forEach(control => delete routeParams[control.name]);

				this._updateUrl(routeParams);
			});
	}

	private _updateRouteParamsInURLOnFilterControlsValuesChange(): void {
		this._filterControls$
			.pipe(
				switchMap(controls => merge(...controls.map(control => control.value$.pipe(
					debounceTime(50, BpScheduler.asyncOutside),
					map((controlValue): [string, unknown] => [ control.name, controlValue ]),

					/*
					 * If more than one the filter control emits a value during the same event loop,
					 * the router will navigate only to the last fired one, but we need to proceed all of them.
					 * Thus in order to update the url for the each value of the each changed filter control
					 * we schedule it at the end of the current event loop
					 */
					observeOn(BpScheduler.asyncInside),
				)))),
				map(([ controlName, controlValue ]): [Params, string, string | null] => [
					this._getRouteParamsAccordingToFilterRouteParamsType(),
					controlName,
					UrlHelper.toRouteParamString(controlValue),
				]),
				filter(([ routeParams, controlName, newRouteValue ]) => newRouteValue !== (routeParams[controlName] ?? null)),
				takeUntilDestroyed(this),
			)
			.subscribe(([ routeParams, controlName, newRouteValue ]) => {

				this.except.forEach(controlNameToExclude => delete routeParams[controlNameToExclude]);

				if (isNil(newRouteValue) || newRouteValue === get(this._defaultsStringed$.value, controlName))

					delete routeParams[controlName];

				else

					routeParams[controlName] = newRouteValue;

				this._updateUrl(routeParams);
			});
	}

	private _getRouteParamsAccordingToFilterRouteParamsType(): Params {
		return this.type === 'matrix'
			? UrlHelper.getLastPrimaryRouteNonNilParams(this._route)
			: UrlHelper.getLastPrimaryRouteQueryParams(this._route);
	}

	private _updateFilterValueOnFilterControlsValuesChange(): void {
		this._filterControls$
			.pipe(
				switchMap(controls => combineLatest(controls.map(control => control.value$.pipe(
					startWith(control.value),
					map((controlValue): [string, unknown] => [ control.name, controlValue ]),
				)))),
				auditTime(50, BpScheduler.asyncOutside),
				observeOn(BpScheduler.inside),
				map(controlValues => <T><unknown>fromPairs(
					controlValues.filter(([ , controlValue ]) => !isNil(controlValue)),
				)),
				takeUntilDestroyed(this),
			)
			.subscribe(controlSelectedValues => void this._value$.next(controlSelectedValues));
	}

	private _updateFilterControlsOnRelevantRouteParamsChange(): void {
		combineLatest([
			this._filterControls$,
			this.type === 'matrix' ? this._route.params : this._route.queryParams,
			this._defaultsStringed$,
		])
			.pipe(
				map(([ controls, routeParams, defaults ]) => controls.map(control => ({
					control,
					routeValue: <string | null>routeParams[control.name] ?? null,
					defaultValue: get(defaults, control.name) ?? null,
				}))),
				startWith(null),
				pairwise(),
				// Update only those controls which needs to be updated
				map(([ previousGroups, nextGroups ]) => nextGroups!.filter(next => {
					const previousGroup = previousGroups?.find(p => next.control.name === p.control.name);

					return !previousGroup || next.routeValue !== previousGroup.routeValue;
				})),
				mergeMap(v => v),
				takeUntilDestroyed(this),
			)
			.subscribe(({ control, routeValue, defaultValue }) => {
				const newControlValue = isNil(routeValue) ? defaultValue : routeValue;

				if (newControlValue !== UrlHelper.toRouteParamString(control.value))
					control.setValue(isNil(newControlValue) ? null : UrlHelper.parseRouteParam(newControlValue));
			});
	}

	private _updateDefaultsStringedOnControlsOrDefaultsChange(): void {
		combineLatest([
			this._filterControls$,
			this._defaults$,
		])
			.pipe(
				filter(([ , defaults ]) => !isEmpty(defaults)),
				map(([ controls, defaults ]) => transform(
					controls,
					(accumulator, control) => set(
						accumulator,
						control.name,
						UrlHelper.toRouteParamString(get(defaults, control.name)),
					),
					{},
				)),
				takeUntilDestroyed(this),
			)
			.subscribe(this._defaultsStringed$);
	}

	private _updateUrl(routeParams: Params): void {
		if (this.type === 'matrix')
			void this._router.navigate([ routeParams ], { relativeTo: this._route });
		else {
			void this._router.navigate([], {
				queryParams: routeParams,
				relativeTo: this._route,
			});
		}
	}

}
