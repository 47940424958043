/* eslint-disable max-classes-per-file */
import { Enumeration } from '@bp/shared/models/core/enum';
import { PaymentCardToken } from '@bp/shared/domains/payment-card-tokens';
import { DTO } from '@bp/shared/models/metadata';

import { PaymentOptionTypeSnakeCaseLiterals } from '@bp/frontend/models/business';

import { CheckoutLanguage } from '../checkout-languages';

import { EventPayload } from './event-payload.type';

export type CheckoutPaymentResult = {
	type: 'approved' | 'cancelled' | 'declined' | 'pending';
	amount?: number;
	currency?: string;
	processingDate?: string;
	maskedCreditCardNumber?: string;
};

type PaymentMethodContext = {
	type: PaymentOptionTypeSnakeCaseLiterals;
	provider: string;
};

export class CheckoutEvent<T extends EventPayload> extends Enumeration {

	static init = new CheckoutEvent<{ type: 'failure' | 'success' }>();

	static clickOnPaymentMethod = new CheckoutEvent<PaymentMethodContext>();

	static paymentMethodOpen = new CheckoutEvent<PaymentMethodContext>();

	static paymentMethodFormDirty = new CheckoutEvent<PaymentMethodContext>();

	static cryptoQuickResponseCodePageOpen = new CheckoutEvent<PaymentMethodContext>();

	static processingDeposit = new CheckoutEvent<PaymentMethodContext & { value: boolean }>();

	static processingPayment = new CheckoutEvent<PaymentMethodContext & { value: boolean }>();

	static requestPaymentSuccess = new CheckoutEvent<PaymentMethodContext>();

	static requestPaymentError = new CheckoutEvent<PaymentMethodContext>();

	static tokenizingPaymentCard = new CheckoutEvent<{ value: boolean }>();

	static paymentCardToken = new CheckoutEvent<DTO<PaymentCardToken>>();

	static loadingPSP = new CheckoutEvent<{
		value: boolean;
		type: 'first-psp' | 'next-psp';
		provider: string;
	}>();

	static processingPSP = new CheckoutEvent<{ value: boolean; provider: string }>();

	static deposit = new CheckoutEvent<CheckoutPaymentResult>();

	static error = new CheckoutEvent<{ error: any }>();

	static contentRendered = new CheckoutEvent();

	static paymentTabOpened = new CheckoutEvent();

	static redirect = new CheckoutEvent<{ url: string }>();

	static currentLanguageSet = new CheckoutEvent<{ language: CheckoutLanguage }>();

	static reload = new CheckoutEvent();

	static closeModal = new CheckoutEvent();

	static logrocketSessionUrl = new CheckoutEvent<{ url: string }>();

	static requestNewOrderId = new CheckoutEvent();

	payloadSchema!: T;

}

export class CheckoutLauncherEvent<T extends EventPayload> extends Enumeration {

	static popupWindowClosed = new CheckoutLauncherEvent();

	payloadSchema!: T;

}

export class CheckoutWalletEvent extends Enumeration {

	static walletResult = new CheckoutWalletEvent();

	static walletProviderCannotBeUsed = new CheckoutWalletEvent();

	static walletButtonIsReady = new CheckoutWalletEvent();

	static noWalletProviderFound = new CheckoutWalletEvent();

	static walletShippingContactSelected = new CheckoutWalletEvent();

	static walletCancelled = new CheckoutWalletEvent();

}
