import { isObject } from 'lodash-es';
import { GlobalThis } from 'type-fest';

import { inject, Injectable } from '@angular/core';

import { PaymentCardToken } from '@bp/shared/domains/payment-card-tokens';
import { toPlainObject } from '@bp/shared/utilities/core';

import { CheckoutEvent, EventPayload } from '@bp/frontend/domains/checkout/core';
import { TelemetryService } from '@bp/frontend/services/telemetry';

@Injectable({
	providedIn: 'root',
})
export class HostNotifierService {

	private readonly __telemetry = inject(TelemetryService);

	private __checkoutKey?: string;

	setCheckoutKey(checkoutKey: string): void {
		this.__checkoutKey = checkoutKey;
	}

	sessionInit(payload: typeof CheckoutEvent.init.payloadSchema): void {
		void this._dispatch({ event: CheckoutEvent.init, ...payload });

		void this._dispatch({ event: 'create-session', ...payload });
	}

	currentLanguageSet(payload: typeof CheckoutEvent.currentLanguageSet.payloadSchema): void {
		void this._dispatch({ event: CheckoutEvent.currentLanguageSet, ...payload });
	}

	clickOnPaymentMethod(payload: typeof CheckoutEvent.clickOnPaymentMethod.payloadSchema): void {
		void this._dispatch({ event: CheckoutEvent.clickOnPaymentMethod, ...payload });
	}

	paymentMethodOpen(payload: typeof CheckoutEvent.paymentMethodOpen.payloadSchema): void {
		void this._dispatch({ event: CheckoutEvent.paymentMethodOpen, ...payload });
	}

	paymentMethodFormDirty(payload: typeof CheckoutEvent.paymentMethodFormDirty.payloadSchema): void {
		void this._dispatch({ event: CheckoutEvent.paymentMethodFormDirty, ...payload });
	}

	cryptoQuickResponseCodePageOpen(
		payload: typeof CheckoutEvent.cryptoQuickResponseCodePageOpen.payloadSchema,
	): void {
		void this._dispatch({ event: CheckoutEvent.cryptoQuickResponseCodePageOpen, ...payload });
	}

	processingPayment(payload: typeof CheckoutEvent.processingDeposit.payloadSchema): void {
		void this._dispatch({
			event: CheckoutEvent.processingDeposit,
			...payload,
		});

		void this._dispatch({
			event: CheckoutEvent.processingPayment,
			...payload,
		});
	}

	requestPaymentSuccess(payload: typeof CheckoutEvent.requestPaymentSuccess.payloadSchema): void {
		void this._dispatch({
			event: CheckoutEvent.requestPaymentSuccess,
			...payload,
		});
	}

	requestPaymentError(payload: typeof CheckoutEvent.requestPaymentError.payloadSchema): void {
		void this._dispatch({
			event: CheckoutEvent.requestPaymentError,
			...payload,
		});
	}

	tokenizingPaymentCard(value: boolean): void {
		void this._dispatch({
			event: CheckoutEvent.tokenizingPaymentCard,
			value,
		});
	}

	paymentCardToken(token: PaymentCardToken): void {
		void this._dispatch({
			event: CheckoutEvent.paymentCardToken,
			...toPlainObject(token),
		});
	}

	paymentTabOpened(): void {
		void this._dispatch({ event: CheckoutEvent.paymentTabOpened });
	}

	loadingPSP(payload: typeof CheckoutEvent.loadingPSP.payloadSchema): void {
		void this._dispatch({ event: CheckoutEvent.loadingPSP, ...payload });
	}

	processingPSP(payload: typeof CheckoutEvent.processingPSP.payloadSchema): void {
		void this._dispatch({ event: CheckoutEvent.processingPSP, ...payload });
	}

	payment(payload: typeof CheckoutEvent.deposit.payloadSchema): void {
		void this._dispatch({ event: CheckoutEvent.deposit, ...payload }); // backward compatibility
	}

	contentRendered(): void {
		void this._dispatch({ event: CheckoutEvent.contentRendered });
	}

	redirectHostPage(url: string): void {
		void this._dispatch({ event: CheckoutEvent.redirect, url });
	}

	reloadHostPage(): void {
		void this._dispatch({ event: CheckoutEvent.reload });
	}

	requestNewOrderId(): void {
		void this._dispatch({ event: CheckoutEvent.requestNewOrderId });
	}

	logrocketSessionUrl(url: string): void {
		void this._dispatch({ event: CheckoutEvent.logrocketSessionUrl, url });
	}

	closeModal(): void {
		void this._dispatch({ event: CheckoutEvent.closeModal });
	}

	error(error: unknown): void {
		void this._dispatch({
			event: CheckoutEvent.error,
			error: error instanceof Error
				? error.message
				: (isObject(error) ? <object>JSON.parse(JSON.stringify(error)) : error),
		});
	}

	private _dispatch<T extends EventPayload>({ event, ...payload }: T & { event: CheckoutEvent<T> | string }): void {
		this.__telemetry.log(`Host Notifying Event: ${ event }`, payload);

		[
			`[bp]:${ event }`, // for the backward compatibility with the existent checkout hosts
			...this.__checkoutKey
				? [
					`[bp][cashier:${ this.__checkoutKey }]:${ event }`, // backward compatibility
					`[bp][checkout:${ this.__checkoutKey }]:${ event }`,
				]
				: [],
		]
			.forEach(checkoutEvent => {
				const targetWindow = window.opener && ('postMessage' in window.opener)
					? <GlobalThis>window.opener
					: window.parent;

				void targetWindow.postMessage(
					{
						event: checkoutEvent,
						...payload,
					},
					'*',
				);
			});
	}
}
