<div class="card-container">
	<section #pspCard class="card psp-card">
		<bp-processing-stub
			[show]="!_embedded.disableProcessingMessages && (_processing$ | async)"
			[secure]="true"
			[retrying]="_isRetrying"
		>
			>
		</bp-processing-stub>

		<div
			*ngIf="(_processing$ | async) === false && _session.isRegularLikeCheckout"
			class="subtext count-down"
			@slide
		>
			<b>{{ _countDown$ | async }}</b> {{ 'page.3d-secure.countdown' | translate }}
		</div>

		<div #pspIframeOutlet class="psp-iframe-outlet" [class.invisible]="_processing$ | async"></div>
	</section>
</div>
