<!-- eslint-disable @angular-eslint/template/no-inline-styles -->
<svg version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink">
	<defs>
		<symbol
			id="transaction-status-arrow-icon"
			viewBox="0 0 15 15"
			style="fill: var(--transaction-status-icon-color, #0f213c)"
		>
			<mask id="a" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="4" y="3" width="7" height="8">
				<path fill-rule="evenodd" clip-rule="evenodd" d="M4.15 10.7V3.65h6.55v7.05H4.15Z" fill="#fff" />
			</mask>
			<g mask="url(#a)">
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="m7.2 3.8-3.03 6.54c-.04.1-.02.23.07.3.1.07.22.08.31 0L7.42 8.5l2.87 2.16a.25.25 0 0 0 .38-.31L7.65 3.8a.25.25 0 0 0-.46 0Z"
				/>
			</g>
		</symbol>

		<symbol id="transaction-status-declined-icon" viewBox="0 0 15 15">
			<path fill-rule="evenodd" clip-rule="evenodd" d="M4.2 7.3h6.44H4.2Z" fill="#fff" />
			<path d="M4.2 7.3h6.44" stroke="#fff" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
		</symbol>

		<symbol id="transaction-status-init-icon" viewBox="0 0 14 14" fill="none">
			<path
				d="M5.83334 10.5561C5.65539 10.5561 5.47787 10.4879 5.34246 10.3525L3.95358 8.96364C3.75479 8.76486 3.69533 8.46625 3.80297 8.2067C3.91061 7.94716 4.16408 7.77832 4.44446 7.77832H9.30554C9.68879 7.77832 9.99999 8.08865 9.99999 8.47276C9.99999 8.85687 9.68879 9.1672 9.30554 9.1672H6.12067L6.32422 9.37076C6.59592 9.64246 6.59592 10.0813 6.32422 10.3525C6.18881 10.4879 6.01086 10.5561 5.83334 10.5561Z"
				style="fill: var(--transaction-status-icon-color, #0f213c)"
			/>
			<path
				d="M9.30553 6.38915H4.44444C4.0612 6.38915 3.75 6.07882 3.75 5.6947C3.75 5.31059 4.0612 5.00026 4.44444 5.00026H7.62932L7.42576 4.79671C7.15406 4.52501 7.15406 4.08621 7.42576 3.81494C7.69746 3.54367 8.13626 3.54324 8.40753 3.81494L9.79641 5.20382C9.99519 5.40261 10.0547 5.70122 9.94702 5.96076C9.83938 6.22031 9.58591 6.38915 9.30553 6.38915Z"
				style="fill: var(--transaction-status-icon-color, #0f213c)"
			/>
		</symbol>

		<symbol id="transaction-status-pending-icon" viewBox="0 0 14 14" fill="none">
			<path
				d="M8.78016 8.71984L7.5 7.43969V4.75C7.5 4.33609 7.16391 4 6.75 4C6.33609 4 6 4.33609 6 4.75V7.75C6 7.94922 6.07922 8.13953 6.21984 8.28016L7.71984 9.78016C7.86656 9.92687 8.05781 10 8.25 10C8.44219 10 8.63391 9.92687 8.78016 9.78016C9.07312 9.48719 9.07312 9.01234 8.78016 8.71984Z"
				style="fill: var(--transaction-status-icon-color, #0f213c)"
			/>
		</symbol>

		<symbol id="transaction-status-pending-icon" viewBox="0 0 14 14" fill="none">
			<path
				d="M8.78016 8.71984L7.5 7.43969V4.75C7.5 4.33609 7.16391 4 6.75 4C6.33609 4 6 4.33609 6 4.75V7.75C6 7.94922 6.07922 8.13953 6.21984 8.28016L7.71984 9.78016C7.86656 9.92687 8.05781 10 8.25 10C8.44219 10 8.63391 9.92687 8.78016 9.78016C9.07312 9.48719 9.07312 9.01234 8.78016 8.71984Z"
				style="fill: var(--transaction-status-icon-color, #0f213c)"
			/>
		</symbol>

		<symbol id="transaction-status-capture-in-progress-icon" viewBox="0 0 14 14" fill="none">
			<path
				d="M4 8C4.55228 8 5 7.55228 5 7C5 6.44772 4.55228 6 4 6C3.44772 6 3 6.44772 3 7C3 7.55228 3.44772 8 4 8Z"
				style="fill: var(--transaction-status-icon-color, #0f213c)"
			/>
			<path
				d="M7.34375 8C7.89603 8 8.34375 7.55228 8.34375 7C8.34375 6.44772 7.89603 6 7.34375 6C6.79147 6 6.34375 6.44772 6.34375 7C6.34375 7.55228 6.79147 8 7.34375 8Z"
				style="fill: var(--transaction-status-icon-color, #0f213c)"
			/>
			<path
				d="M10.6836 8C11.2359 8 11.6836 7.55228 11.6836 7C11.6836 6.44772 11.2359 6 10.6836 6C10.1313 6 9.68359 6.44772 9.68359 7C9.68359 7.55228 10.1313 8 10.6836 8Z"
				style="fill: var(--transaction-status-icon-color, #0f213c)"
			/>
		</symbol>

		<symbol id="transaction-status-filtered-by-rule-icon" viewBox="0 0 14 14" fill="none">
			<path
				d="M9.33327 6.33332C8.71297 6.33332 8.1958 6.75948 8.04717 7.33329H7.33332V7.33466L7.33254 7.33388C6.414 7.33388 5.67027 6.59034 5.66656 5.67318V5.61947C6.24077 5.47084 6.66654 4.95386 6.66654 4.33337C6.66654 3.59686 6.06948 3 5.33317 3C4.59686 3 4 3.59725 4 4.33337C4 4.95386 4.42577 5.47084 4.99998 5.61947V8.38053C4.42577 8.52916 4 9.04614 4 9.66663C4 10.4029 4.59706 11 5.33317 11C6.06929 11 6.66634 10.4029 6.66634 9.66663C6.66634 9.04614 6.24057 8.52936 5.66637 8.38053V7.29872C6.08979 7.73113 6.67943 7.99988 7.33234 7.99988L7.33312 7.9991V7.99988H8.04697C8.1956 8.57369 8.71258 8.99985 9.33307 8.99985C10.0694 8.99985 10.6664 8.4028 10.6664 7.66668C10.6664 6.93057 10.0694 6.33332 9.33307 6.33332H9.33327Z"
				style="fill: var(--transaction-status-icon-color, #0f213c)"
			/>
		</symbol>

		<symbol id="transaction-status-skipped-due-to-missing-fingerprint-icon" viewBox="0 0 10 8" fill="none">
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M2.82831 0.329206C3.35315 0.116676 3.92632 0 4.52561 0C7.0251 0 9.05121 2.02611 9.05121 4.52561C9.05121 4.80335 8.82611 5.02845 8.54837 5.02845C8.27062 5.02845 8.04552 4.80335 8.04552 4.52561C8.04552 2.5816 6.46961 1.00569 4.52561 1.00569C4.05792 1.00569 3.61263 1.09663 3.20583 1.26143C2.94832 1.36574 2.65526 1.2414 2.55096 0.984084C2.44685 0.726572 2.57099 0.433507 2.82831 0.329206ZM1.84128 1.48771C2.04949 1.67157 2.06933 1.98918 1.88547 2.19739C1.33745 2.81829 1.00569 3.63247 1.00569 4.52561C1.00569 4.80335 0.780588 5.02845 0.502845 5.02845C0.225102 5.02845 0 4.80335 0 4.52561C0 3.37829 0.427615 2.32939 1.1316 1.53191C1.31545 1.3237 1.63307 1.30386 1.84128 1.48771Z"
				style="fill: var(--transaction-status-icon-color, #0f213c)"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M2.01245 4.52594C2.01245 3.13742 3.13816 2.01172 4.52668 2.01172C5.9152 2.01172 7.0409 3.13742 7.0409 4.52594C7.0409 4.80369 6.8158 5.02879 6.53806 5.02879C6.26032 5.02879 6.03522 4.80369 6.03522 4.52594C6.03522 3.69271 5.35991 3.01741 4.52668 3.01741C3.69345 3.01741 3.01814 3.69271 3.01814 4.52594C3.01814 5.35917 2.8449 6.17355 2.53141 6.90385C2.4218 7.15901 2.12618 7.27725 1.87103 7.16765C1.61587 7.05804 1.49782 6.76243 1.60723 6.50727C1.86789 5.89993 2.01245 5.23052 2.01245 4.52614V4.52594ZM6.49838 5.53772C6.77259 5.58133 6.95978 5.83884 6.91618 6.11325C6.84998 6.53065 6.75805 6.9394 6.64216 7.33814C6.56477 7.60489 6.28566 7.7581 6.01891 7.68071C5.75217 7.60312 5.59896 7.3242 5.67635 7.05745C5.78045 6.69898 5.86315 6.33108 5.92266 5.95571C5.96627 5.68151 6.22378 5.49431 6.49819 5.53792L6.49838 5.53772Z"
				style="fill: var(--transaction-status-icon-color, #0f213c)"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M4.52489 4.02246C4.80264 4.02246 5.02774 4.24756 5.02774 4.52531C5.02774 5.64983 4.79674 6.72172 4.37915 7.695C4.26974 7.95015 3.97392 8.0684 3.71877 7.9588C3.46361 7.84919 3.34537 7.55357 3.45497 7.29842C3.81973 6.4481 4.02205 5.51096 4.02205 4.52531C4.02205 4.24756 4.24715 4.02246 4.52489 4.02246Z"
				style="fill: var(--transaction-status-icon-color, #0f213c)"
			/>
		</symbol>

		<symbol id="transaction-status-3d-icon" viewBox="0 0 14 14" fill="none">
			<path
				d="M2.54864 8.35513C2.54864 8.35513 3.12174 8.98671 3.99468 8.98671C4.57629 8.98671 5.00053 8.62095 5.00053 8.10526C5.00053 7.58958 4.51887 7.24083 3.88729 7.24083H3.47156L3.25572 6.70069L4.25306 5.3631C4.45295 5.06433 4.64328 4.91441 4.64328 4.91441V4.89739C4.64328 4.89739 4.46039 4.92185 4.12015 4.92185H3.38012C3.26422 4.92185 3.19724 4.98884 3.19724 5.10473V5.46199H2.21584V4.56566C2.21584 4.17438 2.3328 4 2.73153 4H5.93939V4.67305L4.5848 6.36789C5.38224 6.49229 6.10526 7.09091 6.10526 8.08825C6.10526 9.08559 5.34078 10 4.00213 10C2.66348 10 2 9.16959 2 9.16959L2.54864 8.35513Z"
				style="fill: var(--transaction-status-icon-color, #0f213c)"
			/>
			<path
				d="M7.22764 4.92291H6.6875V4H9.08941C10.9097 4 12.0974 5.08028 12.0974 6.94205C12.0974 8.80383 10.9087 9.90112 9.08941 9.90112H7.79329C7.39457 9.90112 7.2287 9.73525 7.2287 9.33546V4.92291H7.22764ZM8.4993 8.9782H9.03093C10.2112 8.9782 10.9671 8.2722 10.9671 6.94205C10.9671 5.61191 10.1941 4.92291 9.03093 4.92291H8.31642V8.79532C8.31642 8.91122 8.38341 8.9782 8.4993 8.9782Z"
				style="fill: var(--transaction-status-icon-color, #0f213c)"
			/>
		</symbol>
	</defs>
</svg>
