import { toggleableStateKeptInLocalStorageFactory } from './toggleable-state-kept-in-local-storage-factory';

const MERCHANT_ADMIN_DESIGN_STATE_STORAGE_KEY = 'merchant-admin-design-state';

export class MerchantAdminDesignState extends toggleableStateKeptInLocalStorageFactory(MERCHANT_ADMIN_DESIGN_STATE_STORAGE_KEY) {

	static defaultState = false;

	static get isV3Mode(): boolean {
		return this.isActive;
	}

	static reloadInV3Mode(): void {
		if (this.isV3Mode)
			return;

		this.set(true);

		globalThis.location.href = '/';
	}

	static reloadInNormalMode(): void {
		if (!this.isV3Mode)
			return;

		this.set(false);

		globalThis.location.href = '/';
	}

}
