import { Enumeration } from '@bp/shared/models/core/enum';

export class CheckoutLaunchButtonMode extends Enumeration {

	static modal = new CheckoutLaunchButtonMode();

	static popupWindow = new CheckoutLaunchButtonMode();

	static tab = new CheckoutLaunchButtonMode();

	static spot = new CheckoutLaunchButtonMode();

	static wallet = new CheckoutLaunchButtonMode();

	static override parseHook = (value: unknown): CheckoutLaunchButtonMode | null => {
		if (value === 'browserCheckout')
			return CheckoutLaunchButtonMode.wallet;

		return null;
	};

	get isWallet(): boolean {
		return this === CheckoutLaunchButtonMode.wallet;
	}

	get isModal(): boolean {
		return this === CheckoutLaunchButtonMode.modal;
	}

	get isPopupWindow(): boolean {
		return this === CheckoutLaunchButtonMode.popupWindow;
	}

	get isTab(): boolean {
		return this === CheckoutLaunchButtonMode.tab;
	}

	get isSpot(): boolean {
		return this === CheckoutLaunchButtonMode.spot;
	}
}
